'use client';

import React from 'react';
import { useTranslations } from 'next-intl';
import { GlowMenuAccordionListItem } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { CategoryValue } from '../../../server';
import { DataLayerClassName } from '../dataLayer';

type MenuSubCategoriesListItemsProps = {
    categoryDataLayerId?: string;
    categories: CategoryValue[];
    seeAllUrl?: string;
    isSeeAllEnabled?: boolean;
    isDesktop: boolean;
};

export const MenuSubCategoriesListItems: Component<MenuSubCategoriesListItemsProps> = ({
    categoryDataLayerId,
    categories,
    seeAllUrl,
    isSeeAllEnabled,
    isDesktop,
}) => {
    const t = useTranslations('Header.menu.label');

    return (
        <>
            {isDesktop && isSeeAllEnabled && (
                <GlowMenuAccordionListItem
                    key={'seeAll'}
                    title={t('seeAll')}
                    href={seeAllUrl}
                    isObfuscated={true}
                    className={DataLayerClassName.categoryNavigation}
                    dataLayerId={categoryDataLayerId}
                    contents={[]}
                    isCollapsible={false}
                    isTitleBold={isDesktop}
                />
            )}
            {categories
                .filter((category) => category.showInMenu)
                .map(({ id, name, color, url, dataLayerId, subCategories }) => {
                    const href = isDesktop ? url : subCategories?.length === 0 ? url : undefined;

                    return (
                        <GlowMenuAccordionListItem
                            key={id}
                            title={name}
                            href={href}
                            isObfuscated={isDesktop}
                            labelColor={color}
                            className={href ? DataLayerClassName.nestedCategoryNavigation : ''}
                            dataLayerId={categoryDataLayerId}
                            dataLayerSubId={dataLayerId}
                            contents={subCategories
                                .filter((category) => category.showInMenu)
                                .map((subCategory) => ({
                                    href: subCategory.url ?? '',
                                    isObfuscated: isDesktop,
                                    id: subCategory.id,
                                    label: subCategory.name,
                                    labelColor: subCategory.color,
                                    dataLayerId: categoryDataLayerId,
                                    dataLayerSubId: subCategory.dataLayerId,
                                    dataLayerClassName: DataLayerClassName.nestedCategoryNavigation,
                                }))}
                            isCollapsible={
                                isDesktop ? false : subCategories.filter((category) => category.showInMenu).length > 0
                            }
                            isTitleBold={isDesktop}
                        />
                    );
                })}
        </>
    );
};
