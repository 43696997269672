'use client';

import { ServerActionName } from '@headless-workspace/config';
import { ResultFailure, ResultServerAction, ResultServerActionType } from '@headless-workspace/core-domain';
import { NumberHelpers } from '@headless-workspace/utils';
import { TriggerWithArgs } from 'swr/mutation';
import {
    BasketErrors,
    CustomerBasketValue,
    updateBasketServerAction,
    UserRevalidationKey,
    useSafeSWRMutation,
} from '../../../../src';

type AddToBasketArgs = {
    id: string;
    quantity?: number;
};

type MutationTriggerReturnType = TriggerWithArgs<
    ResultServerActionType<CustomerBasketValue>,
    unknown,
    typeof UserRevalidationKey.Basket,
    AddToBasketArgs
>;

type UseAddToBasketReturnType = {
    add: MutationTriggerReturnType;
    isMutating: boolean;
};

const DEFAULT_QUANTITY = 1;

export const useBasketMutations = (): UseAddToBasketReturnType => {
    const { trigger: additionTrigger, isMutating } = useSafeSWRMutation<CustomerBasketValue, AddToBasketArgs>(
        UserRevalidationKey.Basket,
        ServerActionName.updateBasket,
        async (_, { arg }) => {
            const sku = NumberHelpers.parseNumber(arg.id);

            if (!sku) {
                return ResultServerAction(
                    ResultFailure(BasketErrors.InvalidProductId, `${arg.id} is not a valid sku`),
                    ServerActionName.updateBasket,
                );
            }
            const basketUpdatePayload = {
                id: sku,
                quantity: arg.quantity ?? DEFAULT_QUANTITY,
            };

            return await updateBasketServerAction([basketUpdatePayload]);
        },
    );

    return {
        add: additionTrigger,
        isMutating,
    };
};
