/**
 * Checks if an HTML element has overflow in the specified direction.
 *
 * This function determines whether the content of a given HTML element
 * overflows its visible area along the specified axis ('x' for horizontal
 * or 'y' for vertical). It returns `true` if there is an overflow, and
 * `false` otherwise.
 *
 * @param {HTMLElement | null} element - The HTML element to check for overflow.
 *                                       If `null`, the function will return `false`.
 * @param {'x' | 'y'} [direction='x'] - The direction to check for overflow.
 *                                      Use 'x' for horizontal overflow and
 *                                      'y' for vertical overflow. Defaults to 'x'.
 * @returns {boolean} - Returns `true` if the element's content overflows
 *                      in the specified direction, otherwise returns `false`.
 */
export const checkOverflow = (element: HTMLElement | null, direction: 'x' | 'y' = 'x'): boolean => {
    if (!element) return false;

    const isOverflowing = (scroll: number, client: number) => scroll > client;

    if (direction === 'x') {
        return isOverflowing(element.scrollWidth, element.clientWidth);
    } else if (direction === 'y') {
        return isOverflowing(element.scrollHeight, element.clientHeight);
    }

    return false;
};

export const OverflowHelpers = {
    checkOverflow,
};
