'use client';

import React from 'react';
import clsx from 'clsx';
import { Component } from '@headless-workspace/typings';
import {
    GlowClickable,
    GlowMenuButton,
    GlowTextBodySmall,
    PropsWithAriaLabel,
    PropsWithClassName,
    PropsWithDataLayer,
} from '../../../../src';
import { Accordion } from './Accordion';
import { GlowAccordion } from './GlowAccordion';

type GlowMenuAccordionListItemProps = PropsWithClassName &
    PropsWithDataLayer & {
        title: string;
        href?: string;
        titleReferent?: string;
        isObfuscated?: boolean;
        labelColor?: string;
        contents: Accordion[];
        isCollapsible: boolean;
        isTitleBold: boolean;
    };

/**
 * This component is used to display the accordion-style category menu.
 * A component that renders an accordion.
 * @param title - The title of the accordion.
 * @param href - The href of the title.
 * @param titleReferent - The title for the anchor. Used when the label is not descriptive enough.
 * @param isObfuscated - A boolean that determines whether the title link is obscured.
 * @param labelColor - The color of the title.
 * @param contents - The contents of the accordion.
 * @param isCollapsible - A boolean that determines if the accordion is collapsible.
 * @param isTitleBold - A boolean that determines if the title is bold.
 * @param dataLayerId - The data layer id of the title component.
 * @param dataLayerSubId - The data layer sub-id of the title component.
 * @param className - Additional styles
 */
export const GlowMenuAccordionListItem: Component<GlowMenuAccordionListItemProps> = ({
    title,
    href,
    titleReferent,
    isObfuscated,
    labelColor,
    contents,
    isCollapsible,
    isTitleBold,
    dataLayerId,
    dataLayerSubId,
    className,
}) => {
    return (
        <GlowAccordion
            title={title}
            href={href}
            titleReferent={titleReferent}
            isObfuscated={isObfuscated}
            labelColor={labelColor}
            contents={contents}
            isCollapsible={isCollapsible}
            titleClassName={clsx('bg-surface-primary p-1 desktop:hover:text-underline', className)}
            itemContainerClassName={'bg-surface-primary desktop:hover:text-underline'}
            TitleComponent={GlowMenuButton}
            ItemComponent={MenuAccordionItem}
            isTitleBold={isTitleBold}
            containerClassName={'desktop:w-menuAccordionItemContainer overflow-hidden'}
            ContainerTagName={'li'}
            dataLayerId={dataLayerId}
            dataLayerSubId={dataLayerSubId}
        />
    );
};

type MenuAccordionItemProps = PropsWithClassName &
    PropsWithAriaLabel &
    PropsWithDataLayer & {
        label: string;
        labelColor?: string;
        href: string;
        titleReferent?: string;
        isObfuscated?: boolean;
    };

const MenuAccordionItem: Component<MenuAccordionItemProps> = ({
    label,
    labelColor,
    href,
    titleReferent,
    isObfuscated,
    className,
    ariaLabel,
    dataLayerId,
    dataLayerSubId,
    dataLayerClassName,
}) => {
    return (
        <GlowClickable
            className={clsx('flex-1', 'px-2 py-0.75 desktop:px-0 desktop:py-0.5', className, dataLayerClassName)}
            style={{ color: labelColor }}
            ariaLabel={ariaLabel}
            href={href}
            titleReferent={titleReferent}
            dataLayerId={dataLayerId}
            dataLayerSubId={dataLayerSubId}
            content={{
                labelElement: (
                    <GlowTextBodySmall
                        text={label}
                        className={'text-wrap text-left'}
                        style={labelColor ? { color: 'inherit' } : undefined}
                        TagName={isObfuscated ? 'span' : 'p'}
                    />
                ),
            }}
            isObfuscated={isObfuscated}
        />
    );
};
