import { z } from 'zod';

export const BasketsDTO = z.object({
    baskets: z.array(
        z.object({
            id: z.string(),
        }),
    ),
});

export type BasketsDTO = z.infer<typeof BasketsDTO>;
