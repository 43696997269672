'use client';

import React, { useCallback } from 'react';
import { useTranslations } from 'next-intl';
import { buildRoute } from '@headless-workspace/config';
import { Icons } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import {
    ConnectionBannerItem,
    getConnectedUserInfo,
    useAppLocale,
    useClientData,
    useLongSession,
    useUserInfo,
} from '../../../../../src';
import { CommonDI, UserSessionRepositoryType } from '../../../../DI';

export type ConnectionBannerLogoutButtonProps = {
    onLogout: (state: boolean) => void;
    isLoggingOut: boolean;
    hostUrl: string;
};

export const ConnectionBannerLogoutButton: Component<ConnectionBannerLogoutButtonProps> = ({
    onLogout,
    isLoggingOut,
    hostUrl,
}) => {
    const t = useTranslations('Header.connection');
    const locale = useAppLocale();
    const { getClientEnvVariable } = useClientData();
    const { result: userInfoResult, isLoading } = useUserInfo();
    const { logout: longSessionLogout } = useLongSession(locale, hostUrl);

    const skeletonProps = {
        width: '11.188rem',
        height: '2rem',
        itemClassName: 'rounded-0.5',
    };

    const logout = useCallback(async () => {
        onLogout(true);

        // Call logout server action and 3rd party long session logout
        // we use allSettled to ensure both actions are completed (even failing) before redirecting
        await Promise.allSettled([CommonDI.get(UserSessionRepositoryType).deleteSession(locale), longSessionLogout()]);

        const logoutUrl = `${hostUrl}${buildRoute(locale, 'logout')}`;

        // Redirect to Legacy logout route to clear cookies
        window.location.replace(logoutUrl);
    }, [getClientEnvVariable, locale, longSessionLogout, onLogout]);

    return (
        (getConnectedUserInfo(userInfoResult) || isLoading) && (
            <ConnectionBannerItem
                Icon={Icons.Logout}
                label={t('banner.action.disconnect')}
                onClick={logout}
                isObfuscated
                colorClassName={'!text-text-warning'}
                className={'fill-text-warning'}
                disabled={isLoggingOut}
                isRedirecting={isLoggingOut || isLoading}
                skeleton={skeletonProps}
            />
        )
    );
};
