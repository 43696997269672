import { Locale } from '@headless-workspace/config';
import { ConfigByKeyRepository, Result } from '@headless-workspace/core-domain';
import { FreeShippingPriceDTO } from './FreeShippingPriceDTO';
import { FreeShippingPriceValue, mapFreeShippingPrice } from './FreeShippingPriceValue';

export interface FreeShippingPriceRepositorySpec {
    fetchFreeShippingPrice(locale: Locale): Promise<Result<FreeShippingPriceValue>>;
}

const TOTAL_FOR_FREE_SHIPPING = 'bpProductPriceShippingThresholdJSON';

export class FreeShippingPriceRepository extends ConfigByKeyRepository implements FreeShippingPriceRepositorySpec {
    async fetchFreeShippingPrice(locale: Locale): Promise<Result<FreeShippingPriceValue>> {
        return await this.getConfigByKey(TOTAL_FOR_FREE_SHIPPING, { locale }, (value) =>
            mapFreeShippingPrice(FreeShippingPriceDTO.parse(value), locale),
        );
    }
}
