import { z } from 'zod';
import { CookieMutationAction } from './CookieMutationAction';

export const CookieMutationPayloadDTO = z.object({
    action: CookieMutationAction,
    input: z.string().optional(),
    locale: z.string().optional(),
    hostUrl: z.string().optional(),
});

export type CookieMutationPayloadDTO = z.infer<typeof CookieMutationPayloadDTO>;
