'use client';

import { useTranslations } from 'next-intl';
import { ServerActionName } from '@headless-workspace/config';
import { AsyncData, ResultEmpty, ResultFailure } from '@headless-workspace/core-domain';
import {
    BasketErrors,
    CustomerBasketValue,
    getBasketServerAction,
    UserRevalidationKey,
    useSafeSWR,
    VipBrandsValue,
} from '../../../../src';

type UseBasketSummaryReturnType = AsyncData<CustomerBasketValue>;

export const useBasketProductsList = (vipBrands: VipBrandsValue): UseBasketSummaryReturnType => {
    const t = useTranslations('Header.basket.banner.error');
    const { data, isLoading, error } = useSafeSWR(UserRevalidationKey.Basket, ServerActionName.getBasket, () =>
        getBasketServerAction(vipBrands),
    );

    if (!data) {
        if (error) {
            // Only when error is thrown on first call (before we have any data)
            // Otherwise we return previous valid data
            return {
                isLoading,
                result: ResultFailure(
                    BasketErrors.Default,
                    error instanceof Error ? error.message : t(BasketErrors.Default),
                ),
            };
        }

        return { isLoading, result: ResultEmpty() };
    }

    return { isLoading, result: data };
};
