import { FlagDTO } from './FlagDTO';

export type FlagValue = {
    id: string;
    label: string;
};

export const mapFlag = ({ id, label }: FlagDTO): FlagValue | null => (id && label ? { id, label } : null);

export const mapFlags = (flags?: FlagDTO[]): FlagValue[] | undefined =>
    flags?.map(mapFlag).filter((flag): flag is FlagValue => flag !== null);
