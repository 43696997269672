'use client';

import React, { ReactNode, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { Position } from '@headless-workspace/config';
import { Component } from '@headless-workspace/typings';
import { PropsWithClassName } from '../../props';
import { FocusTrap } from '../../utils';

type GlowPopoverProps = PropsWithClassName & {
    isOpen: boolean;
    map: { default: ReactNode } & Record<string, ReactNode>;
    navigationStack: string[];
    onClickOutside?: () => void;
    side: Position;
};

export const GlowMenuModal: Component<GlowPopoverProps> = ({
    className,
    map,
    onClickOutside,
    isOpen,
    side,
    navigationStack,
}) => {
    const containerRef = React.useRef<HTMLElement>(null);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTo(0, 0);
        }
    }, [navigationStack]);

    const isLeftSideMenu = useMemo(() => side === Position.Left, [side]);
    const isAnimationDisabled = useMemo(() => side === Position.None, [side]);

    return (
        <FocusTrap isActive={isOpen}>
            <div
                role={'button'}
                aria-hidden={'true'}
                data-testid={'background'}
                className={clsx(
                    'fixed z-sideMenuBackground inset-0 overflow-hidden bg-background-overlay',
                    isLeftSideMenu ? 'left-0' : 'right-0',
                    isOpen ? 'flex' : 'hidden',
                )}
                onClick={onClickOutside}
            />
            <nav
                ref={containerRef}
                className={clsx(
                    'fixed z-sideMenu inset-y-0',
                    'overflow-x-hidden',
                    isLeftSideMenu ? 'left-0' : 'right-0',
                    !isAnimationDisabled && 'duration-slow1 ease-sideMenu',
                    isOpen ? 'translate-x-0' : isLeftSideMenu ? '-translate-x-full' : 'translate-x-full',
                    isOpen && isAnimationDisabled && 'left-0',
                    className,
                )}
            >
                {map.default && (
                    <div className={clsx(navigationStack.length !== 0 ? 'max-h-fullViewport overflow-hidden' : '')}>
                        {map.default}
                    </div>
                )}
                {Object.keys(map).map((id) => {
                    return id === 'default' ? null : (
                        <div
                            id={`side-menu-${id}`}
                            key={id}
                            className={clsx(
                                'absolute right-0',
                                'min-h-fullViewport w-full',
                                'bg-surface-neutral',
                                'duration-slow1 ease-sideMenuModal',
                                'overflow-x-hidden',
                                navigationStack.includes(id)
                                    ? 'translate-x-0'
                                    : isLeftSideMenu
                                    ? 'translate-x-full size-0 overflow-hidden'
                                    : '-translate-x-full',
                            )}
                        >
                            {map[id]}
                        </div>
                    );
                })}
            </nav>
        </FocusTrap>
    );
};
