export const fr_FR_NavRoutes = {
    home: '',
    connection: 'connexion',
    storesAndServices: 'tous-les-magasins',
    wishlist: 'wishlist',
    basket: 'panier',
    payment: '', // FIXME: To Update
    account: 'mon-compte',
    orders: 'mes-achats',
    followOrder: 'on/demandware.store/Sites-Sephora_FR-Site/fr_FR/Order-TrackMyOrder',
    loyalty: 'Programme-carte-fidelite.html',
    offers: 'mes-offres',
    profile: 'profil',
    mail: 'profil/?is_communication_link=true',
    help: 'aide-sephora',
    allServices: 'nos-services-en-magasin.html',
    storeInfo: 'information-magasin',
    allStores: 'tous-les-magasins/',
    countries: 'pays.html',
    beautyTips: 'cadeaux-bon-plan-beaute-bon-plan-beaute',
    logout: 'deconnexion/?redirectToReferer=true',
    loyaltyProgram: 'Programme-carte-fidelite.html',
    allBrands: 'marques/de-a-a-z/',
    clickAndCollect: 'services-commande.html',
    securePayment: 'paiement-securise.html',
    returnProduct: 'aide-sephora/?question=order-topic5-question1',
    privacyPolicy: 'donnees-personnelles.html',
    search: 'on/demandware.store/Sites-Sephora_FR-Site/fr_FR/Search-AlgoliaSearch?q=',
    reviewConditions: 'general-conditions-publication-reviews.html',
};
