'use client';

import { useState } from 'react';
import clsx from 'clsx';
import {
    GlowClickable,
    GlowIcon,
    GlowTag,
    GlowTextBody,
    GlowTextBodySmall,
    IconName,
    Icons,
} from '@headless-workspace/glow-ds';
import { Component, DeliveryMethod } from '@headless-workspace/typings';
import { StringHelpers } from '@headless-workspace/utils';
import fullClickable from '../../../../styles/fullClickable.module.css';
import { UIAccordion } from '../accordion';
import { UIStoreOpenHours } from './UIStoreOpenHours';

export type UIStoreInfoCardProps = {
    favoriteLabel?: string;
    store: {
        id: string;
        isSelected: boolean;
        name: string;
        addressText: string;
        distanceText: string;
        productAvailability: {
            isAvailable: boolean;
            text: string;
        };
        scheduleInfo: {
            openStatusTitle: string;
            openStatusText: string;
            openHours: { day: string; hours: string }[];
        };
        shippingMethodInfo: {
            groupId: DeliveryMethod;
            freeLabel?: string;
            deliveryMessage: string;
        };
    };
    closeOptions?: {
        label: string;
        onClose: () => void;
    };
    onSelect?: (id: string) => void;
};

const HYPHEN_PREFIX = '\u00A0- ';

export const UIStoreInfoCard: Component<UIStoreInfoCardProps> = ({
    favoriteLabel = '',
    store,
    closeOptions,
    onSelect,
}) => {
    const [isSelected, setIsSelected] = useState(store.isSelected);

    const handleStoreSelection = () => {
        setIsSelected(true);
        onSelect?.(store.id);
    };

    return (
        <div
            className={clsx(
                'relative p-1 grid gap-0.5',
                'border-solid rounded-0.5 border-medium',
                isSelected ? 'outline outline-medium outline-border-selected' : 'border-border-primary ',
                fullClickable.container,
            )}
        >
            {favoriteLabel.length > 0 && (
                <GlowTag label={favoriteLabel} size={'medium'} type={'info'} iconName={IconName.Star} />
            )}
            <div className="flex">
                {onSelect ? (
                    <GlowClickable
                        onClick={handleStoreSelection}
                        className={fullClickable.element}
                        content={{
                            labelElement: <GlowTextBody text={store.name} fontWeight={'bold'} />,
                        }}
                    />
                ) : (
                    <GlowTextBody text={store.name} fontWeight={'bold'} />
                )}
                <GlowTextBody
                    text={StringHelpers.addPrefix(store.distanceText, HYPHEN_PREFIX, true)}
                    color={'tertiary'}
                />
            </div>
            <GlowTextBodySmall
                text={store.productAvailability.text}
                fontWeight={'bold'}
                color={store.productAvailability.isAvailable ? 'success' : 'error'}
            />
            <GlowTextBodySmall text={store.addressText} color={'tertiary'} />
            <UIAccordion
                className={'z-1'}
                accordionId={`${store.id}-open-hours`}
                title={
                    <p className={'flex'}>
                        <GlowTextBodySmall text={store.scheduleInfo.openStatusTitle} fontWeight={'bold'} />
                        <GlowTextBodySmall
                            text={StringHelpers.addPrefix(store.scheduleInfo.openStatusText, HYPHEN_PREFIX, true)}
                            fontWeight={'medium'}
                        />
                    </p>
                }
            >
                <UIStoreOpenHours openHours={store.scheduleInfo.openHours} textSize={'small'} />
            </UIAccordion>
            <div className={'flex items-center justify-between'}>
                <GlowTextBodySmall text={store.shippingMethodInfo.deliveryMessage} fontWeight="bold" />
                {store.shippingMethodInfo.freeLabel && (
                    <GlowTag label={store.shippingMethodInfo.freeLabel} size={'large'} type={'success'} />
                )}
            </div>
            {closeOptions && (
                <GlowClickable
                    className={'absolute right-1 top-1'}
                    ariaLabel={closeOptions.label}
                    content={{
                        icon: <GlowIcon Icon={Icons.Cross} type={'large'} />,
                    }}
                    onClick={closeOptions.onClose}
                />
            )}
        </div>
    );
};
