import {
    DEFAULT_DOMAIN_CONFIG_MAP,
    DefaultValueConfig,
    DOMAIN_CONFIG_MAP,
    EnvConfig,
    envConfig,
    Language,
    Locale,
} from '@headless-workspace/config';
import { IsomorphicLogger } from './logger';

const getUrlDomain = (url?: string): string | undefined => {
    return url?.split('/')[2] ?? url;
};

const getCustomBaseUrl = (url: string, tld?: string): string => {
    const parsedUrl = new URL(url);
    const { protocol, hostname } = parsedUrl;
    const hostnameParts = hostname.split('.');
    const subdomain = hostnameParts.length > 2 ? hostnameParts.slice(0, -1).join('.') : parsedUrl.hostname;
    const CustomBaseUrl = `${protocol}//${subdomain}`;
    return tld ? `${CustomBaseUrl}${tld}` : parsedUrl.origin;
};

const getUrlSubDomain = (url: string, domain: string): string | undefined => {
    const index = url.indexOf(domain);
    // Return undefined if the domain does not match the url
    if (index === -1) {
        return undefined;
    }
    return url.substring(0, index).split('.')[0];
};

const getHostname = (url: string): string | undefined => {
    return URL.canParse(url) ? new URL(url).hostname : undefined;
};

const createPathFromEnv = (path: string | undefined): string | undefined => {
    if (!path) {
        return undefined;
    }

    return path.replace(/{([a-zA-Z]+)}/g, (_, key) => {
        const envValue = envConfig[key as keyof EnvConfig];
        if (envValue && typeof envValue === 'string') {
            return envValue;
        }
        return '';
    });
};

const extractProductIdFromUrl = (slug: string): string | undefined => {
    if (slug.indexOf('-') !== -1) {
        if (slug.length > 1024) {
            return undefined;
        }
        const matches = slug.match(/([A-Za-z]*\d+)(?=\.html)/g);
        return matches ? matches[0] : undefined;
    }
    return slug.split('.')[0];
};

// TODO : Implement proper extractor for PLP when url pattern will be available
const extractCategoryIdFromUrl = (slug: string): string | undefined => {
    return extractProductIdFromUrl(slug);
};

const getHrefPathname = (url: string, lang?: string): string => {
    try {
        const href = new URL(url);
        const languagePrefix = lang ?? '';
        return `${languagePrefix}${href.pathname}${href.search}`;
    } catch (error) {
        new IsomorphicLogger().error('Invalid URL', error);
        return DefaultValueConfig.href;
    }
};

type DomainConfig = {
    defaultLocale: Locale;
    languages: Language[];
};

const getDomainConfig = (domain: string): DomainConfig => {
    return (
        Object.entries(DOMAIN_CONFIG_MAP).find(([suffix]) => domain.endsWith(`.${suffix}`))?.[1] ||
        DEFAULT_DOMAIN_CONFIG_MAP
    );
};

const getTLD = (url: string) => {
    const parsedUrl = new URL(url);
    const hostname = parsedUrl.hostname;
    return hostname.split('.').pop();
};

const removeLanguageCode = (pathname: string) => {
    return pathname.replace(/^\/[a-z]{2}(?:\/|$)/, '/');
};

/**
 * Builds URL string from protocol and host
 * @param proto - protocol, e.g. http, https
 * @param host - host, e.g. localhost:3000
 */
const buildUrlFromProtoAndHost = (proto: string | null, host: string | null): string | null => {
    if (!proto || !host || (proto !== 'http' && proto !== 'https')) {
        return null;
    }
    return `${proto}://${host}`;
};

export const UrlHelpers = {
    getUrlDomain,
    getUrlSubDomain,
    getHostname,
    createPathFromEnv,
    extractProductIdFromUrl,
    extractCategoryIdFromUrl,
    getHrefPathname,
    getDomainConfig,
    getCustomBaseUrl,
    getTLD,
    removeLanguageCode,
    buildUrlFromProtoAndHost,
};
