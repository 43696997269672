import { z } from 'zod';

export const ScheduleDTO = z.object({
    day: z.number(),
    date: z.string().optional(),
    workingHours: z
        .array(
            z.object({
                from: z.string(),
                to: z.string(),
            }),
        )
        .optional(),
    workingHoursText: z.string().optional(),
});

export type ScheduleDTO = z.infer<typeof ScheduleDTO>;
