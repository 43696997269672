import { ApiConfig } from '@headless-workspace/config';
import { CookieMutatedDTO, CookieMutationAction, initRequestStrategy } from '@headless-workspace/data';
import { Repository, Result, ResultFailure, ResultSuccess } from '../types';

export interface UserCookiesRepositorySpec {
    revalidateSessionCookies(locale: string, revalidationKey: string): Promise<Result<boolean>>;
    createGuestProductIdCookie(locale: string, productId: string): Promise<Result<boolean>>;
    deleteFavoriteStoreCookie(hostUrl: string): Promise<Result<boolean>>;
}

export class UserCookiesRepository extends Repository implements UserCookiesRepositorySpec {
    path = ApiConfig.headless.paths.user;

    constructor(requestStrategy = initRequestStrategy()) {
        super(
            requestStrategy.authStrategy,
            requestStrategy.refreshTokenStrategy,
            requestStrategy.queryParamStrategy,
            ApiConfig.headless.basePath,
        );
    }

    private async mutateCookie(
        action: CookieMutationAction,
        locale?: string,
        input?: string,
        hostUrl?: string,
    ): Promise<Result<boolean>> {
        const url = `${this.path}/${ApiConfig.headless.endpoints.cookies}`;
        try {
            const response = await this.datasource.createResource<
                { action: CookieMutationAction; locale?: string; input?: string; hostUrl?: string },
                CookieMutatedDTO
            >(url, {
                action,
                locale,
                input,
                hostUrl,
            });

            const { cookieMutated } = CookieMutatedDTO.parse(response);

            return ResultSuccess(cookieMutated);
        } catch (error) {
            const apiError = this.handleApiError(error);
            return ResultFailure(apiError.code, apiError.message, {
                locale,
                url,
            });
        }
    }

    async revalidateSessionCookies(locale: string, revalidationKey: string): Promise<Result<boolean>> {
        return this.mutateCookie('revalidateSession', locale, revalidationKey);
    }

    async createGuestProductIdCookie(locale: string, productId: string): Promise<Result<boolean>> {
        return this.mutateCookie('enqueueGuestProduct', locale, productId);
    }

    async deleteFavoriteStoreCookie(hostUrl: string): Promise<Result<boolean>> {
        return this.mutateCookie('deleteFavoriteStore', hostUrl);
    }
}
