'use client';

import { useEffect } from 'react';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { GlowClickable, GlowTextBodySmall, PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { DateHelpers } from '@headless-workspace/utils';
import { InnerHTML, PersistentBannerSlotValue, useNavigation, usePersistentBannerCountDown } from '../../../../src';
import { CountDown, CountDownType } from './countDown';

type PersistentBannerProps = PropsWithLocale & {
    data: PersistentBannerSlotValue;
};

export const PersistentBanner: Component<PersistentBannerProps> = ({ data }) => {
    const t = useTranslations('Header.persistentBanner');
    const endOfCountDown = DateHelpers.isoToTimestampWithTZ(data.endOfCountDownDateISO);
    const { days, hours, minutes, seconds, startCountdown } = usePersistentBannerCountDown(endOfCountDown);
    const navigation = useNavigation();
    const timeValues: CountDownType[] = [
        { value: days, label: t('countDown.days') },
        { value: hours, label: t('countDown.hours') },
        { value: minutes, label: t('countDown.minutes') },
        { value: seconds, label: t('countDown.seconds') },
    ];

    useEffect(() => {
        startCountdown();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClickPersistentBanner = () => {
        if (data.href) {
            navigation.push(data.href);
        }
    };

    const textColor = 'primary-fixed';

    return (
        <div
            className={clsx(
                'flex flex-row flex-wrap flex-grow-1',
                'justify-center items-center text-center',
                'gap-x-0.5 py-0.5 px-2 tablet:px-1 desktopS:px-1.5',
                data.href && 'cursor-pointer',
            )}
            onClick={onClickPersistentBanner}
            role={data.href ? 'button' : 'banner'}
            style={{ backgroundColor: data.backgroundColor }}
            tabIndex={0}
            onKeyDown={onClickPersistentBanner}
        >
            {endOfCountDown > 0 && <CountDown values={timeValues} />}
            <GlowTextBodySmall innerHTML={<InnerHTML rawHtml={data.text} />} TagName={'div'} color={textColor} />
            {data.href && (
                <GlowClickable
                    href={data.href}
                    content={{
                        labelElement: (
                            <GlowTextBodySmall text={data.linkLabel} textDecoration={'underline'} color={textColor} />
                        ),
                    }}
                />
            )}
        </div>
    );
};
