import { LocalePrefix } from './LocalePrefix';

export type EnvConfig = {
    headlessVersion: string;
    apiBaseUrl: string;
    apiKey: string;
    baseHref: string;
    clientApiKey: string;
    cookieDomain: string;
    defaultCachePolicy: RequestCache;
    i18nLocaleDetectionEnabled: boolean;
    i18nLocalePrefix: LocalePrefix;
    runtimeEnvironment: string;
    buildEnvironment: string;
    trackingBaseUrl: string;
    trackingABTestPath: string;
    FRTrackingAnalyticsPath: string;
    FRTrackingMediaPath: string;
    PLTrackingAnalyticsPath: string;
    PLTrackingMediaPath: string;
    ITTrackingAnalyticsPath: string;
    ITTrackingMediaPath: string;
    ESTrackingAnalyticsPath: string;
    ESTrackingMediaPath: string;
    DETrackingAnalyticsPath: string;
    DETrackingMediaPath: string;
    CZTrackingAnalyticsPath: string;
    CZTrackingMediaPath: string;
    ROTrackingAnalyticsPath: string;
    ROTrackingMediaPath: string;
    DKTrackingAnalyticsPath: string;
    DKTrackingMediaPath: string;
    SETrackingAnalyticsPath: string;
    SETrackingMediaPath: string;
    PTTrackingAnalyticsPath: string;
    PTTrackingMediaPath: string;
    TRTrackingAnalyticsPath: string;
    TRTrackingMediaPath: string;
    GRTrackingAnalyticsPath: string;
    GRTrackingMediaPath: string;
    CHTrackingAnalyticsPath: string;
    CHTrackingMediaPath: string;
    booxiApiKey: string;
    woosmapApiBaseUrl: string;
    woosmapApiKey: string;
    fetchTimeoutMs: number;
    netStorageSecret: string;
    netStorageDomain: string;
    netStorageUploadAccountId: string;
    netStorageCPCode: string;
    netStorageUID: string;
    netStorageENV: string;
    timeMachineEnabled: boolean;
    opentelemetryEnabled: boolean;
    appVersion?: string;
    reachFiveClientId: string;
    reachFiveDomain: string;
    serverEncryptionIV: string;
    serverEncryptionSalt: string;
    serverEncryptionSecret: string;
};

const DEFAULT_CACHE_POLICY: RequestCache = 'no-store';
const DEFAULT_RUNTIME_ENVIRONMENT = 'prod';
const DEFAULT_BUILD_ENVIRONMENT = 'prd';
const DEFAULT_FETCH_TIMEOUT_MS = 30000;
const DEFAULT_BASE_HREF = '';

export const createEnvConfig = (): EnvConfig => {
    return {
        headlessVersion: process.env['NEXT_PUBLIC_HEADLESS_VERSION'],
        apiBaseUrl: process.env['API_BASE_URL'],
        apiKey: process.env['API_KEY'],
        baseHref: process.env['BASE_HREF'] ?? DEFAULT_BASE_HREF,
        clientApiKey: process.env['CLIENT_API_KEY'],
        cookieDomain: process.env['COOKIE_DOMAIN'],
        defaultCachePolicy: process.env['DEFAULT_CACHE_POLICY'] ?? DEFAULT_CACHE_POLICY,
        i18nLocaleDetectionEnabled: process.env['I18N_LOCALE_DETECTION_ENABLED'] === 'true',
        i18nLocalePrefix: (process.env['I18N_LOCALE_PREFIX'] as LocalePrefix) ?? LocalePrefix.Always,
        runtimeEnvironment: process.env['RUNTIME_ENV'] ?? DEFAULT_RUNTIME_ENVIRONMENT,
        buildEnvironment: process.env['ENV_BUILD'] ?? DEFAULT_BUILD_ENVIRONMENT,
        trackingBaseUrl: process.env['TRACKING_BASE_URL'],
        trackingABTestPath: process.env['TRACKING_AB_TEST_PATH'],
        FRTrackingAnalyticsPath: process.env['FR_TRACKING_ANALYTICS_PATH'],
        FRTrackingMediaPath: process.env['FR_TRACKING_MEDIA_PATH'],
        PLTrackingAnalyticsPath: process.env['PL_TRACKING_ANALYTICS_PATH'],
        PLTrackingMediaPath: process.env['PL_TRACKING_MEDIA_PATH'],
        ITTrackingAnalyticsPath: process.env['IT_TRACKING_ANALYTICS_PATH'],
        ITTrackingMediaPath: process.env['IT_TRACKING_MEDIA_PATH'],
        ESTrackingAnalyticsPath: process.env['ES_TRACKING_ANALYTICS_PATH'],
        ESTrackingMediaPath: process.env['ES_TRACKING_MEDIA_PATH'],
        DETrackingAnalyticsPath: process.env['DE_TRACKING_ANALYTICS_PATH'],
        DETrackingMediaPath: process.env['DE_TRACKING_MEDIA_PATH'],
        CZTrackingAnalyticsPath: process.env['CZ_TRACKING_ANALYTICS_PATH'],
        CZTrackingMediaPath: process.env['CZ_TRACKING_MEDIA_PATH'],
        ROTrackingAnalyticsPath: process.env['RO_TRACKING_ANALYTICS_PATH'],
        ROTrackingMediaPath: process.env['RO_TRACKING_MEDIA_PATH'],
        DKTrackingAnalyticsPath: process.env['DK_TRACKING_ANALYTICS_PATH'],
        DKTrackingMediaPath: process.env['DK_TRACKING_MEDIA_PATH'],
        SETrackingAnalyticsPath: process.env['SE_TRACKING_ANALYTICS_PATH'],
        SETrackingMediaPath: process.env['SE_TRACKING_MEDIA_PATH'],
        PTTrackingAnalyticsPath: process.env['PT_TRACKING_ANALYTICS_PATH'],
        PTTrackingMediaPath: process.env['PT_TRACKING_MEDIA_PATH'],
        TRTrackingAnalyticsPath: process.env['TR_TRACKING_ANALYTICS_PATH'],
        TRTrackingMediaPath: process.env['TR_TRACKING_MEDIA_PATH'],
        GRTrackingAnalyticsPath: process.env['GR_TRACKING_ANALYTICS_PATH'],
        GRTrackingMediaPath: process.env['GR_TRACKING_MEDIA_PATH'],
        CHTrackingAnalyticsPath: process.env['CH_TRACKING_ANALYTICS_PATH'],
        CHTrackingMediaPath: process.env['CH_TRACKING_MEDIA_PATH'],
        booxiApiKey: process.env['BOOXI_API_KEY'],
        woosmapApiBaseUrl: process.env['WOOSMAP_API_BASE_URL'],
        woosmapApiKey: process.env['WOOSMAP_API_KEY'],
        fetchTimeoutMs: Number(process.env['FETCH_TIMEOUT_MS'] ?? DEFAULT_FETCH_TIMEOUT_MS),
        netStorageSecret: process.env['NETSTORAGE_SECRET'],
        netStorageDomain: process.env['NETSTORAGE_DOMAIN'],
        netStorageUploadAccountId: process.env['NETSTORAGE_UPLOAD_ACCOUNT_ID'],
        netStorageCPCode: process.env['NETSTORAGE_CP_CODE'],
        netStorageUID: process.env['NETSTORAGE_UID'],
        netStorageENV: process.env['NETSTORAGE_ENV'],
        timeMachineEnabled: process.env['TIME_MACHINE_ENABLED'] === 'true',
        opentelemetryEnabled: process.env['OPENTELEMETRY_ENABLED'] === 'true',
        appVersion: process.env['NEXT_PUBLIC_APP_VERSION'],
        reachFiveClientId: process.env['REACH_FIVE_CLIENT_ID'],
        reachFiveDomain: process.env['REACH_FIVE_DOMAIN'],
        serverEncryptionIV: process.env['SERVER_ENCRYPTION_IV'],
        serverEncryptionSalt: process.env['SERVER_ENCRYPTION_SALT'],
        serverEncryptionSecret: process.env['SERVER_ENCRYPTION_SECRET'],
    };
};

export const envConfig = createEnvConfig();

export const isServer = (): boolean => !!process.env['NEXT_RUNTIME'];
