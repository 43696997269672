import { useCallback, useContext } from 'react';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { PERSONAL_DATA_MENU_ID } from '@headless-workspace/config';
import { UIContext } from '@headless-workspace/core-ui';
import {
    GlowButton,
    GlowClickable,
    GlowDivider,
    GlowIcon,
    GlowTitle,
    Icons,
    PropsWithLocale,
} from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { HTMLString } from '../../../../server';
import { InnerHTML } from '../../innerHTML';

type PersonalDataMenuProps = PropsWithLocale & {
    modalTitle: string;
    description: HTMLString;
    offerLegalMention?: HTMLString;
    isNewsletterBoostEnabled: boolean;
};

export const PersonalDataMenu: Component<PersonalDataMenuProps> = ({
    modalTitle,
    description,
    offerLegalMention,
    isNewsletterBoostEnabled,
}) => {
    const {
        menuModal: { close: closeMenu },
    } = useContext(UIContext);

    const t = useTranslations('Discover.Home.newsletter.menu');

    const onClose = useCallback(() => {
        closeMenu(PERSONAL_DATA_MENU_ID);
    }, [closeMenu]);

    return (
        <div
            className={clsx(
                'tablet:w-full w-fullViewport h-fullViewport overflow-hidden',
                'flex flex-col',
                'bg-background-l3',
            )}
        >
            <div className={clsx('flex flex-row justify-between items-center', 'py-0.75 px-1 gap-1')}>
                <GlowTitle text={modalTitle} fontSize={'title4'} TagName={'h2'} />
                <GlowClickable
                    onClick={onClose}
                    content={{
                        icon: <GlowIcon Icon={Icons.Cross} type={'large'} />,
                    }}
                />
            </div>
            <GlowDivider />
            <div className={'flex flex-col flex-grow-1 p-1 gap-1 bg-background-l3 overflow-y-scroll text-body'}>
                <InnerHTML
                    className={'newsletter-banner-description text-bodySmall font-brand'}
                    rawHtml={description}
                />
                {offerLegalMention && isNewsletterBoostEnabled && (
                    <InnerHTML className={'newsletter-banner text-bodySmall font-brand'} rawHtml={offerLegalMention} />
                )}
            </div>
            <GlowDivider />
            <div className={clsx('flex items-center justify-center', 'px-1 py-0.75')}>
                <GlowButton className={'flex-1'} onClick={onClose} label={t('action.confirm')} variant={'secondary'} />
            </div>
        </div>
    );
};
