'use client';

import React from 'react';
import { useTranslations } from 'next-intl';
import { GlowButton } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { LanguageHelpers } from '@headless-workspace/utils';
import { bookNow, ClientEnvKey, useAppLocale, useClientData } from '../../../../../src';

export const AppointmentButton: Component = () => {
    const t = useTranslations('Header.storesAndServices');
    const { getClientEnvVariable } = useClientData();
    const locale = useAppLocale();

    const onMakeAppointment = () => {
        bookNow(getClientEnvVariable(ClientEnvKey.BooxiKey), LanguageHelpers.parseLanguage(locale));
    };

    return (
        <GlowButton
            className={'transition-backgroundOutline'}
            label={t('banner.action.makeAppointment')}
            onClick={onMakeAppointment}
        />
    );
};
