'use client';

import React from 'react';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { buildRoute } from '@headless-workspace/config';
import {
    GlowButton,
    GlowDivider,
    GlowTextBody,
    GlowTextList,
    GlowTitle,
    PropsWithLocale,
} from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { AppointmentButton, StoreSearchContainer } from '../../../../../src';

type StoreLocatorProps = PropsWithLocale & {
    favoriteStoreItems: string[];
};

export const StoreLocator: Component<StoreLocatorProps> = ({ locale, favoriteStoreItems }) => {
    const t = useTranslations('Header.storesAndServices');

    return (
        <>
            <div className={'flex flex-col flex-1 gap-2'}>
                <GlowTitle text={t('banner.label.storesTitle')} fontSize={'title4'} />
                <StoreSearchContainer />
                <div>
                    <GlowTextBody text={t('banner.label.chooseStore')} />
                    <GlowTextList className={'flex-1 marker:text-text-primary'} texts={favoriteStoreItems} />
                </div>
            </div>
            <GlowDivider vertical={true} />
            <div className={clsx('flex flex-col flex-1 gap-2')}>
                <div className={'flex flex-col gap-y-0.5'}>
                    <GlowTitle text={t('banner.label.servicesTitle')} fontSize={'title4'} />
                    <GlowTextBody text={t('banner.label.servicesDescription')} />
                </div>
                <div className={'flex flex-col gap-1'}>
                    <AppointmentButton />
                    <GlowButton
                        className={'transition-backgroundOutline'}
                        variant={'secondary'}
                        label={t('banner.action.seeServices')}
                        href={buildRoute(locale, 'allServices')}
                    />
                </div>
            </div>
        </>
    );
};
