'use client';

import { useTranslations } from 'next-intl';
import { SearchConfig, ServerActionName } from '@headless-workspace/config';
import { AsyncData, ResultEmpty, ResultFailure, ResultSuccess } from '@headless-workspace/core-domain';
import { StringHelpers } from '@headless-workspace/utils';
import {
    getSearchSuggestionsServerAction,
    SearchSuggestionsValue,
    useDebounceValue,
    VipBrandsValue,
} from '../../../../../src';
import { useSafeSWR } from '../../hooks/useSafeSwr';
import { SearchSuggestionsErrors } from './SearchSuggestionsErrors';

const SEARCH_SUGGESTIONS_KEY = 'search/suggestions/';
const MAX_SEARCHES_COUNT = 6;

type UseSearchSuggestionsReturnType = AsyncData<SearchSuggestionsValue> & {
    shouldDisplayTags: boolean;
};

export const useSearchSuggestions = (
    searchInput: string,
    vipBrands: VipBrandsValue,
): UseSearchSuggestionsReturnType => {
    const t = useTranslations('Header.search.error');

    const debouncedInput = useDebounceValue(searchInput, SearchConfig.debounceTime);
    const { data, error, isLoading } = useSafeSWR(
        debouncedInput && `${SEARCH_SUGGESTIONS_KEY}${debouncedInput}`,
        ServerActionName.getSearchSuggestions,
        () => getSearchSuggestionsServerAction(debouncedInput, vipBrands),
        { revalidateOnFocus: false, revalidateOnReconnect: false },
    );

    if (!debouncedInput) {
        if (StringHelpers.isNotEmpty(searchInput)) {
            return { isLoading: true, result: ResultEmpty(), shouldDisplayTags: false };
        } else {
            return { isLoading: false, result: ResultEmpty(), shouldDisplayTags: false };
        }
    }

    if (!data || data.type === 'empty') {
        if (error) {
            return {
                isLoading,
                result: ResultFailure(
                    SearchSuggestionsErrors.Default,
                    error instanceof Error ? error.message : t('default'),
                ),
                shouldDisplayTags: false,
            };
        }

        return { isLoading, result: ResultEmpty(), shouldDisplayTags: false };
    }

    return {
        isLoading,
        result: ResultSuccess({
            ...data.data,
            products: data.data.products.slice(0, MAX_SEARCHES_COUNT),
        }),
        shouldDisplayTags: data.data.terms.length > 0 || data.data.categories.length > 0 || data.data.brands.length > 0,
    };
};
