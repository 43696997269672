import clsx from 'clsx';
import {
    GlowButton,
    GlowIcon,
    Icons,
    PropsWithAriaLabel,
    PropsWithClassName,
    GlowButtonProps,
    GlowIconProps,
} from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';

type CarouselNavigationButtonProps = PropsWithClassName &
    PropsWithAriaLabel & {
        type: 'prev' | 'next';
        onClick: () => void;
        disabled: boolean;
        iconType?: GlowIconProps['type'];
        variant?: GlowButtonProps['variant'];
        size?: GlowButtonProps['size'];
    };

export const CarouselNavigationButton: Component<CarouselNavigationButtonProps> = ({
    className,
    ariaLabel,
    type,
    onClick,
    disabled,
    iconType = 'large',
    variant = 'primary',
    size = 'medium',
}) => {
    return (
        <GlowButton
            className={clsx('p-0.5 rounded-0.5', className)}
            onClick={onClick}
            disabled={disabled}
            Icon={() => (
                <GlowIcon Icon={Icons.ChevronRight} className={clsx(type === 'prev' && 'rotate-180')} type={iconType} />
            )}
            ariaLabel={ariaLabel}
            variant={disabled ? 'disabled' : variant}
            size={size}
        />
    );
};
