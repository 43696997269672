'use client';

import { useEffect } from 'react';
import Image from 'next/image';
import { useTranslations } from 'next-intl';
import { DataTestConfig } from '@headless-workspace/config';
import { GlowButton, GlowSearchProductListItem, GlowTitle } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { StringHelpers } from '@headless-workspace/utils';
import { useAppLocale } from '../../../../client';
import { VipBrandsValue } from '../../../../server';
import { SearchSuggestionsContainerSkeleton } from './SearchSuggestionsContainerSkeleton';
import { Suggest } from './Suggest';
import { useSearchSuggestions } from './useSearchSuggestions';

type SearchSuggestionsContainerProps = {
    searchInput: string;
    onSearch: (search: string) => void;
    onEmpty: () => void;
    onResult: () => void;
    vipBrands: VipBrandsValue;
};

export const SearchSuggestionsContainer: Component<SearchSuggestionsContainerProps> = ({
    searchInput,
    onSearch,
    onEmpty,
    onResult,
    vipBrands,
}) => {
    const t = useTranslations('Header.search');
    const {
        isLoading,
        result: searchSuggestionsResult,
        shouldDisplayTags,
    } = useSearchSuggestions(searchInput, vipBrands);
    const locale = useAppLocale();

    useEffect(() => {
        if (!isLoading && searchSuggestionsResult.type !== 'success') {
            onEmpty();
        } else if (!isLoading && searchSuggestionsResult.type === 'success') {
            onResult();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchSuggestionsResult, onEmpty, onResult]);

    if (isLoading) {
        return <SearchSuggestionsContainerSkeleton searchInput={searchInput} onSearch={onSearch} />;
    }

    if (searchSuggestionsResult.type !== 'success') {
        return null;
    }

    return (
        <>
            {searchSuggestionsResult.data.products.length > 0 && (
                <section className={'flex flex-col flex-1 gap-1.5 flex-grow-3'}>
                    <GlowTitle text={t('label.products')} fontSize={'title5'} />
                    <ul
                        className={'grid grid-cols-1 tablet:grid-cols-2 gap-y-1.5 gap-x-2.5 justify-center items-start'}
                        data-testid={DataTestConfig.testIds.searchProductsBanner}
                    >
                        {searchSuggestionsResult.data.products.map((product, index) => (
                            <GlowSearchProductListItem
                                {...product}
                                price={StringHelpers.formatPrice(product.price, product.currency, locale)}
                                priceBeforeDiscount={
                                    product.priceBeforeDiscount
                                        ? StringHelpers.formatPrice(
                                              product.priceBeforeDiscount,
                                              product.currency,
                                              locale,
                                          )
                                        : undefined
                                }
                                discountPercentageLabel={
                                    product.discountPercentage
                                        ? t('label.discount', {
                                              percentage: product.discountPercentage,
                                          })
                                        : undefined
                                }
                                key={`${product.id}-${index}`}
                                engravingOptionLabel={product.isEngravable ? t('label.engravingOption') : undefined}
                                ImageTagName={Image}
                            />
                        ))}
                    </ul>
                    <GlowButton
                        className={'w-maxContent'}
                        label={t('action.viewAllProducts')}
                        onClick={() => onSearch(searchInput)}
                        dataTestId={DataTestConfig.testIds.searchShowAllProductsButton}
                    />
                </section>
            )}
            {shouldDisplayTags && (
                <section className={'flex flex-col gap-1.5 flex-1'}>
                    <Suggest
                        title={t('label.suggestions')}
                        category={'suggestion'}
                        suggestions={searchSuggestionsResult.data.terms}
                        locale={locale}
                    />
                    <Suggest
                        title={t('label.categories')}
                        category={'category'}
                        suggestions={searchSuggestionsResult.data.categories}
                        locale={locale}
                    />
                    <Suggest
                        title={t('label.brands')}
                        category={'brand'}
                        suggestions={searchSuggestionsResult.data.brands}
                        locale={locale}
                    />
                </section>
            )}
        </>
    );
};
