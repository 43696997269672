export enum ClientEnvKey {
    BooxiKey = 'booxiKey',
    WoosmapApiBaseUrl = 'woosmapApiBaseUrl',
    WoosmapKey = 'woosmapKey',
    ClientApiKey = 'clientApiKey',
    BuildEnvironment = 'buildEnvironment',
    RuntimeEnvironment = 'runtimeEnvironment',
    HeadlessVersion = 'headlessVersion',
    ReachFiveClientId = 'reachFiveClientId',
    ReachFiveDomain = 'reachFiveDomain',
}

export type ClientEnvKeysRecord = { [key in ClientEnvKey]: string };
