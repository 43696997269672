'use client';

import { Locale } from '@headless-workspace/config';
import { Result, ResultFailure, ResultSuccess } from '@headless-workspace/core-domain';
import { LanguageHelpers, RumLogger } from '@headless-workspace/utils';
import { createClient } from '@reachfive/identity-core';
import { ClientEnvKey, useClientData } from '../providers';
import { LongSessionError } from './LongSessionError';

export type UseLongSessionReturnType = {
    logout: () => Promise<Result<boolean>>;
};

/**
 * Custom hook to manage long session logout feature using ReachFive.
 * @param {Locale} locale - The locale to be used for the session.
 * @returns {UseLongSessionReturnType} - An object containing the logout function.
 */
export const useLongSession = (locale: Locale, hostUrl: string): UseLongSessionReturnType => {
    const { getClientEnvVariable, getClientFlags } = useClientData();

    const logout = async (): Promise<Result<boolean>> => {
        if (!getClientFlags('isReachFiveEnabled')) {
            return ResultSuccess(false);
        }

        try {
            const client = createClient({
                domain: getClientEnvVariable(ClientEnvKey.ReachFiveDomain),
                clientId: getClientEnvVariable(ClientEnvKey.ReachFiveClientId),
                language: LanguageHelpers.parseLanguage(locale),
            });
            await client.logout({ redirectTo: hostUrl });
            return ResultSuccess(true);
        } catch (error) {
            let errorMessage: string | undefined;

            if (error instanceof Error) {
                errorMessage = error.message;
                new RumLogger().error(LongSessionError.ReachFiveError, error);
            }

            return ResultFailure(LongSessionError.ReachFiveError, errorMessage);
        }
    };

    return { logout };
};
