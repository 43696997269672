'use client';

import React from 'react';
import { useTranslations } from 'next-intl';
import { buildRoute } from '@headless-workspace/config';
import { UIStoreOpenHours } from '@headless-workspace/core-ui';
import { GlowButton, GlowClickable, GlowIcon, GlowTextBody, GlowTitle, Icons } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { LanguageHelpers } from '@headless-workspace/utils';
import { bookNow, ClientEnvKey, StoreService, useAppLocale, useClientData } from '../../../../../src';

export type FavoriteStoreInfoProps = {
    id: string;
    name: string;
    address: string;
    availableServices: StoreService[];
    openHours: { day: string; hours?: string }[];
    deleteFavoriteStore: () => void;
};

export const FavoriteStoreInfo: Component<FavoriteStoreInfoProps> = ({
    id,
    name,
    address,
    availableServices,
    openHours,
    deleteFavoriteStore,
}) => {
    const t = useTranslations('Header.storesAndServices');
    const locale = useAppLocale();
    const { getClientEnvVariable } = useClientData();

    const onDeleteFavoriteStore = () => {
        deleteFavoriteStore();
    };

    const onMakeAppointment = () => {
        bookNow(getClientEnvVariable(ClientEnvKey.BooxiKey), LanguageHelpers.parseLanguage(locale));
    };

    return (
        <section className={'flex flex-1'}>
            <div className={'flex flex-1 flex-col gap-y-2'}>
                <div className={'flex flex-col gap-1'}>
                    <div className={'flex items-center gap-0.5'}>
                        <GlowClickable
                            onClick={onDeleteFavoriteStore}
                            content={{
                                icon: (
                                    <GlowIcon
                                        Icon={Icons.Star}
                                        type={'large'}
                                        className={'fill-text-primary'}
                                        alt={t('banner.alternativeText.favoriteStore')}
                                    />
                                ),
                            }}
                        />
                        <GlowTitle text={t('banner.label.storeName', { name })} fontSize={'title4'} />
                    </div>
                    <GlowTextBody text={address} />
                </div>
                <div className={'flex flex-1'}>
                    <div
                        className={
                            'flex-1 flex flex-col gap-2 pr-2 border-solid border-r-medium border-r-border-primary'
                        }
                    >
                        <div className={'flex flex-col gap-0.5'}>
                            <GlowTextBody text={t('banner.label.hours')} fontWeight={'bold'} />
                            <UIStoreOpenHours openHours={openHours} />
                        </div>
                        <div className={'flex flex-col gap-1'}>
                            <GlowButton
                                className={'transition-backgroundOutline'}
                                variant={'primary'}
                                label={t('banner.action.seeStoreInfo')}
                                href={buildRoute(locale, 'storeInfo', { storeID: id })}
                            />
                            <GlowButton
                                className={'transition-backgroundOutline'}
                                variant={'secondary'}
                                label={t('banner.action.searchAnotherStore')}
                                href={buildRoute(locale, 'allStores')}
                            />
                        </div>
                    </div>
                    <div className={'flex-1 flex flex-col gap-2 pl-2'}>
                        {availableServices.length > 0 && (
                            <div className={'flex flex-col gap-0.5'}>
                                <GlowTextBody text={t('banner.label.availableServices')} fontWeight={'bold'} />
                                <ul>
                                    {availableServices.map((service) => (
                                        <li key={service.id}>
                                            <GlowTextBody text={service.name} />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        <div className={'flex flex-col gap-1'}>
                            <GlowButton
                                className={'transition-backgroundOutline'}
                                variant={'primary'}
                                label={t('banner.action.makeAppointment')}
                                onClick={onMakeAppointment}
                            />
                            <GlowButton
                                className={'transition-backgroundOutline'}
                                variant={'secondary'}
                                label={t('banner.action.seeServices')}
                                href={buildRoute(locale, 'allServices')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
