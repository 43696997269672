import clsx from 'clsx';
import { Component } from '@headless-workspace/typings';
import {
    GlowButton,
    GlowImage,
    GlowRating,
    GlowSkeleton,
    GlowTag,
    GlowTextBody,
    GlowTextBodySmall,
    GlowTextCaption,
    GlowWishlistButton,
    PropsWithFontFamily,
    PropsWithImageTagName,
    PropsWithProduct,
} from '../../../../src';
import { GlowCTA } from '../cta/GlowCTA';
import { ProductPrices } from '../productPrices/ProductPrices';
import { ProductTileContentSkeleton, ProductTileImgSkeleton } from './ProductTileSkeleton';

type ProductTag = {
    id: string;
    label: string;
};

export type ProductTile = PropsWithProduct & {
    sku: string; // FIXME: refacto props to remove sku
    tags?: ProductTag[];
    minimalProductPriceLabel: string;
    minPrice?: string;
    basePriceLabel: string;
    type?: string;
    rating?: number;
    review?: {
        count: number;
        label: string;
    };
    noReviewsLabel: string;
    variantPossibilities: string;
    engravingOptionLabel?: string;
    totalIndividualPriceLabel?: string;
    variantName?: string;
    button: {
        label: string;
        onClick?: (productId?: string) => void;
    };
    legalMentionsLabel?: string;
};

type GlowProductTileProps = PropsWithImageTagName &
    ProductTile & {
        isTablet: boolean;
        isLoading?: boolean;
        wishlist: {
            onClick: () => void;
            isAdded: boolean;
            ariaLabel: string;
        };
    };

const PRODUCT_TILE_IMG_SIZE_MOBILE = 152;
const PRODUCT_TILE_IMG_SIZE = 208;

export const GlowProductTile: Component<GlowProductTileProps> = ({
    id,
    name,
    description,
    href,
    image,
    brand,
    price,
    minPrice,
    priceBeforeDiscount,
    pricePerUnit,
    rating,
    review,
    tags,
    button,
    wishlist,
    isTablet,
    isArialFont,
    minimalProductPriceLabel,
    ImageTagName,
    priceAlwaysBlack,
    discountPercentageLabel,
    basePriceLabel,
    noReviewsLabel,
    isLoading = false,
    variantName,
    variantPossibilities,
    engravingOptionLabel,
    totalIndividualPriceLabel,
    legalMentionsLabel,
}) => {
    const fontFamily = isArialFont ? 'arial' : 'brand';

    const handleOpenMenu = (productId: string) => () => {
        button.onClick?.(productId);
    };

    return (
        <div
            data-product-id={id}
            className={clsx(
                'relative flex flex-col justify-between gap-0.5',
                'h-full w-productTileMobile tablet:w-productTile',
                'p-0.5 pb-3 tablet:p-1 tablet:pb-3', // pb-3 = padding bottom => 48px (24px for absolute, 8px for gap between content and button, 16px for padding bottom)
                'border-medium border-solid rounded-0.5 border-border-primary',
                'bg-surface-primary',
            )}
        >
            <TagsAndWishlistButton tags={tags} wishlist={wishlist} isLoading={isLoading} />
            <GlowCTA className={'flex flex-col justify-between gap-0.5 pt-0.25 h-full relative top-2'} href={href}>
                <div className={'flex flex-col gap-0.5'}>
                    {isLoading ? (
                        <ProductTileImgSkeleton />
                    ) : (
                        <GlowImage
                            image={image}
                            className={
                                'mx-auto h-productTileImgMobile w-productTileImgMobile tablet:h-productTileImg tablet:w-productTileImg'
                            }
                            width={isTablet ? PRODUCT_TILE_IMG_SIZE : PRODUCT_TILE_IMG_SIZE_MOBILE}
                            height={isTablet ? PRODUCT_TILE_IMG_SIZE : PRODUCT_TILE_IMG_SIZE_MOBILE}
                            loading={'lazy'}
                            TagName={ImageTagName}
                            imgClassName={'object-cover rounded-0.5'}
                        />
                    )}
                    <div className={'flex flex-col text-left gap-0.25'}>
                        <GlowTextBody
                            className={'truncate'}
                            text={brand.name}
                            fontWeight={'bold'}
                            fontFamily={fontFamily}
                        />
                        <div className={'overflow-hidden h-productTileDetails'}>
                            <GlowTextBodySmall
                                className={'line-clamp-2 text-ellipsis overflow-hidden whitespace-normal'}
                                text={name}
                                fontFamily={fontFamily}
                            />
                            <GlowTextBodySmall className={'truncate'} text={description} fontFamily={fontFamily} />
                        </div>
                        <GlowTextBodySmall
                            className={'line-clamp-1'}
                            text={variantName}
                            color={'tertiary'}
                            fontFamily={fontFamily}
                        />
                    </div>

                    {isLoading ? (
                        <ProductTileContentSkeleton />
                    ) : (
                        <>
                            <ProductPrices
                                fontFamily={fontFamily}
                                minPrice={minPrice}
                                price={price}
                                priceBeforeDiscount={priceBeforeDiscount}
                                pricePerUnit={pricePerUnit}
                                minimalProductPriceLabel={minimalProductPriceLabel}
                                basePriceLabel={basePriceLabel}
                                priceAlwaysBlack={priceAlwaysBlack}
                                discountPercentageLabel={discountPercentageLabel}
                                totalIndividualPriceLabel={totalIndividualPriceLabel}
                                legalMentionsLabel={legalMentionsLabel}
                            />
                            <RatingAndReview
                                rating={rating}
                                review={review}
                                noReviewsLabel={noReviewsLabel}
                                fontFamily={fontFamily}
                            />
                            <div className={'flex flex-col'}>
                                <GlowTextCaption
                                    className={'line-clamp-1'}
                                    text={variantPossibilities}
                                    color={'primary'}
                                    fontFamily={fontFamily}
                                />
                                <GlowTextCaption
                                    className={'line-clamp-1'}
                                    text={engravingOptionLabel}
                                    color={'primary'}
                                    fontFamily={fontFamily}
                                />
                                {legalMentionsLabel && (
                                    <GlowTextCaption
                                        className={'line-clamp-2'}
                                        text={legalMentionsLabel}
                                        color={'primary'}
                                        fontFamily={fontFamily}
                                    />
                                )}
                            </div>
                        </>
                    )}
                </div>
            </GlowCTA>

            {button.onClick !== undefined ? (
                <GlowButton
                    label={button.label}
                    variant={'secondary'}
                    className={'relative top-2'}
                    fontFamily={fontFamily}
                    onClick={handleOpenMenu(id)}
                />
            ) : (
                <GlowButton
                    label={button.label}
                    variant={'secondary'}
                    className={'relative top-2'}
                    fontFamily={fontFamily}
                    href={href}
                />
            )}
        </div>
    );
};

type TagsAndWishlistButtonProps = {
    tags?: ProductTag[];
    wishlist: {
        onClick: () => void;
        isAdded: boolean;
        ariaLabel: string;
    };
    isLoading: boolean;
};

const TagsAndWishlistButton: Component<TagsAndWishlistButtonProps> = ({ tags, wishlist, isLoading }) => {
    return (
        <div
            className={clsx(
                'flex flex-row',
                !isLoading && (tags && tags.length > 0 ? 'justify-between' : 'justify-end'),
                'absolute z-productTileTag w-productTileMobileWithoutPadding tablet:w-productTileWithoutPadding',
            )}
        >
            {isLoading ? (
                <GlowSkeleton width={'4rem'} height={'1.5rem'} />
            ) : (
                <>
                    {tags && (
                        <div className={'flex flex-col gap-0.25'}>
                            {tags.map((tag) => (
                                <GlowTag key={tag.id} label={tag.label} type={'marketing'} size={'large'} />
                            ))}
                        </div>
                    )}
                    <GlowWishlistButton
                        onClick={wishlist.onClick}
                        isInWishlist={wishlist.isAdded}
                        ariaLabel={wishlist.ariaLabel}
                    />
                </>
            )}
        </div>
    );
};

type RatingAndReviewProps = PropsWithFontFamily & {
    rating?: number;
    review?: {
        count: number;
        label: string;
    };
    noReviewsLabel: string;
};

const RatingAndReview: Component<RatingAndReviewProps> = ({ rating, review, noReviewsLabel, fontFamily }) => {
    if (rating && review) {
        return (
            <div
                className={'flex flex-row items-center gap-0.25'}
                data-testid={'product-tile-rating-and-review-container'}
            >
                <GlowRating rating={rating} className={'text-caption leading-4 gap-[0.188rem]'} />
                <GlowTextCaption text={`${review.count} ${review.label}`} color={'secondary'} fontFamily={fontFamily} />
            </div>
        );
    }

    return <GlowTextCaption text={noReviewsLabel} color={'secondary'} fontFamily={fontFamily} />;
};
