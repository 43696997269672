import {
    DynamicHrefValue,
    DynamicIconValue,
    DynamicLabelValue,
    DynamicTagValue,
} from '@headless-workspace/core-domain';
import { useFindSpecificityRule } from './useFindSpecificityRule';

type DynamicData = DynamicHrefValue | DynamicIconValue | DynamicLabelValue | DynamicTagValue;

export const useCountrySpecificities = <T extends DynamicData>(id: string, dynamicData: T[] | undefined) => {
    const { isLoading, result } = useFindSpecificityRule(id, dynamicData ?? []);

    const data = result && result.type === 'success' ? result.data : null;

    return { isLoading, data };
};
