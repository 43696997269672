import { FunctionHelpers, ObjectHelpers } from '@headless-workspace/utils';
import { PropsWithIcon } from '../../props';
import PlusIconSvg from './Plus.svg?react';
import ArrowLeftIconSvg from './arrowLeft.svg?react';
import BagIconSvg from './bag.svg?react';
import BasketIconSvg from './basket.svg?react';
import BasketAnimationIconSvg from './basketAnimation.svg?react';
import BlackFidIconSvg from './blackFid.svg?react';
import BoxIconSvg from './box.svg?react';
import BurgerIconSvg from './burger.svg?react';
import CalendarIconSvg from './calendar.svg?react';
import CameraSlashIconSvg from './cameraSlash.svg?react';
import CheckSvg from './check.svg?react';
import ChevronDownIconSvg from './chevronDown.svg?react';
import ChevronRightIconSvg from './chevronRight.svg?react';
import CrossIconSvg from './cross.svg?react';
import DeliveryIconSvg from './delivery.svg?react';
import DislikeIconSvg from './dislike.svg?react';
import DownloadAppFromAppStoreIconSvg from './downloadAppFromAppStore.svg?react';
import DownloadAppFromGooglePlayIconSvg from './downloadAppFromGooglePlay.svg?react';
import ErrorSearchIconSvg from './errorSearch.svg?react';
import GiftIconSvg from './gift.svg?react';
import GoldFidIconSvg from './goldFid.svg?react';
import HeartIconSvg from './heart.svg?react';
import HeartAnimationIconSvg from './heartAnimation.svg?react';
import HelpIconSvg from './help.svg?react';
import InfoSvg from './info.svg?react';
import LikeIconSvg from './like.svg?react';
import LocationIconSvg from './location.svg?react';
import LogoSephoraIconSvg from './logoSephora.svg?react';
import LogoutIconSvg from './logout.svg?react';
import MailIconSvg from './mail.svg?react';
import MoonIconSvg from './moon.svg?react';
import MultiFid from './multiFid.svg?react';
import MultiFidUnlimited from './multiFidUnlimited.svg?react';
import MyOffersGoldUnlimited from './myOffersGoldUnlimited.svg?react';
import NewsletterIconSvg from './newsletter.svg?react';
import NotificationIconSvg from './notification.svg?react';
import PauseIconSvg from './pause.svg?react';
import PinIconSvg from './pin.svg?react';
import PlayIconSvg from './play.svg?react';
import ProfileIconSvg from './profile.svg?react';
import ReceiptIconSvg from './receipt.svg?react';
import ReturnsIconSvg from './returns.svg?react';
import SearchIconSvg from './search.svg?react';
import SecurePaymentIconSvg from './securePayment.svg?react';
import SettingIconSvg from './setting.svg?react';
import SpinnerIconSvg from './spinner.svg?react';
import StarIconSvg from './star.svg?react';
import StorefrontIconSvg from './storefront.svg?react';
import StrokeDownIconSvg from './strokeDown.svg?react';
import SuccessIconSvg from './success.svg?react';
import SunIconSvg from './sun.svg?react';
import SystemIconSvg from './system.svg?react';
import TruckIconSvg from './truck.svg?react';
import VectorIconSvg from './vector.svg?react';
import VerifiedIconSvg from './verified.svg?react';
import WarningIconSvg from './warning.svg?react';
import WhiteFidIconSvg from './whiteFid.svg?react';
import ZoomInSvg from './zoomIn.svg?react';
import ZoomOutSvg from './zoomOut.svg?react';

export enum IconName {
    ArrowLeft = 'ArrowLeft',
    Bag = 'Bag',
    Basket = 'Basket',
    BasketAnimation = 'BasketAnimation',
    BlackFid = 'BlackFid',
    Box = 'Box',
    Burger = 'Burger',
    Calendar = 'Calendar',
    CameraSlash = 'CameraSlash',
    Check = 'Check',
    ChevronDown = 'ChevronDown',
    ChevronRight = 'ChevronRight',
    Cross = 'Cross',
    Delivery = 'Delivery',
    Dislike = 'Dislike',
    DownloadAppFromAppStore = 'DownloadAppFromAppStore',
    DownloadAppFromGooglePlay = 'DownloadAppFromGooglePlay',
    ErrorSearch = 'ErrorSearch',
    Gift = 'Gift',
    GoldFid = 'GoldFid',
    Heart = 'Heart',
    HeartAnimation = 'HeartAnimation',
    Help = 'Help',
    Info = 'Info',
    Like = 'Like',
    Location = 'Location',
    LogoSephora = 'LogoSephora',
    Logout = 'Logout',
    Mail = 'Mail',
    Moon = 'Moon',
    MultiFidUnlimited = 'MultiFidUnlimited',
    MyOffersGoldUnlimited = 'MyOffersGoldUnlimited',
    MultiFid = 'MultiFid',
    Newsletter = 'Newsletter',
    Notification = 'Notification',
    Pause = 'Pause',
    Pin = 'Pin',
    Play = 'Play',
    Plus = 'Plus',
    Profile = 'Profile',
    Receipt = 'Receipt',
    Returns = 'Returns',
    Search = 'Search',
    SecurePayment = 'SecurePayment',
    Setting = 'Setting',
    Spinner = 'Spinner',
    Star = 'Star',
    Success = 'Success',
    Sun = 'Sun',
    Storefront = 'Storefront',
    StrokeDown = 'StrokeDown',
    System = 'System',
    Truck = 'Truck',
    Vector = 'Vector',
    Verified = 'Verified',
    Warning = 'Warning',
    WhiteFid = 'WhiteFid',
    ZoomIn = 'ZoomIn',
    ZoomOut = 'ZoomOut',
}

export const Icons: Record<IconName, PropsWithIcon['Icon']> = {
    [IconName.ArrowLeft]: ArrowLeftIconSvg,
    [IconName.Bag]: BagIconSvg,
    [IconName.Basket]: BasketIconSvg,
    [IconName.BasketAnimation]: BasketAnimationIconSvg,
    [IconName.BlackFid]: BlackFidIconSvg,
    [IconName.Box]: BoxIconSvg,
    [IconName.Burger]: BurgerIconSvg,
    [IconName.Calendar]: CalendarIconSvg,
    [IconName.CameraSlash]: CameraSlashIconSvg,
    [IconName.Check]: CheckSvg,
    [IconName.ChevronDown]: ChevronDownIconSvg,
    [IconName.ChevronRight]: ChevronRightIconSvg,
    [IconName.Cross]: CrossIconSvg,
    [IconName.Delivery]: DeliveryIconSvg,
    [IconName.Dislike]: DislikeIconSvg,
    [IconName.DownloadAppFromAppStore]: DownloadAppFromAppStoreIconSvg,
    [IconName.DownloadAppFromGooglePlay]: DownloadAppFromGooglePlayIconSvg,
    [IconName.ErrorSearch]: ErrorSearchIconSvg,
    [IconName.Gift]: GiftIconSvg,
    [IconName.GoldFid]: GoldFidIconSvg,
    [IconName.Heart]: HeartIconSvg,
    [IconName.HeartAnimation]: HeartAnimationIconSvg,
    [IconName.Help]: HelpIconSvg,
    [IconName.Info]: InfoSvg,
    [IconName.Like]: LikeIconSvg,
    [IconName.Location]: LocationIconSvg,
    [IconName.LogoSephora]: LogoSephoraIconSvg,
    [IconName.Logout]: LogoutIconSvg,
    [IconName.Mail]: MailIconSvg,
    [IconName.Moon]: MoonIconSvg,
    [IconName.MultiFidUnlimited]: MultiFidUnlimited,
    [IconName.MyOffersGoldUnlimited]: MyOffersGoldUnlimited,
    [IconName.MultiFid]: MultiFid,
    [IconName.Newsletter]: NewsletterIconSvg,
    [IconName.Notification]: NotificationIconSvg,
    [IconName.Play]: PlayIconSvg,
    [IconName.Plus]: PlusIconSvg,
    [IconName.Pin]: PinIconSvg,
    [IconName.Pause]: PauseIconSvg,
    [IconName.Profile]: ProfileIconSvg,
    [IconName.Receipt]: ReceiptIconSvg,
    [IconName.Returns]: ReturnsIconSvg,
    [IconName.Search]: SearchIconSvg,
    [IconName.SecurePayment]: SecurePaymentIconSvg,
    [IconName.Setting]: SettingIconSvg,
    [IconName.Spinner]: SpinnerIconSvg,
    [IconName.Star]: StarIconSvg,
    [IconName.Sun]: SunIconSvg,
    [IconName.Success]: SuccessIconSvg,
    [IconName.Storefront]: StorefrontIconSvg,
    [IconName.StrokeDown]: StrokeDownIconSvg,
    [IconName.System]: SystemIconSvg,
    [IconName.Truck]: TruckIconSvg,
    [IconName.Vector]: VectorIconSvg,
    [IconName.Verified]: VerifiedIconSvg,
    [IconName.Warning]: WarningIconSvg,
    [IconName.WhiteFid]: WhiteFidIconSvg,
    [IconName.ZoomIn]: ZoomInSvg,
    [IconName.ZoomOut]: ZoomOutSvg,
};

export const getIcon = (icon: string | undefined): PropsWithIcon['Icon'] => {
    if (icon === undefined || !ObjectHelpers.isEnumValue(icon, IconName)) {
        return FunctionHelpers.nullFn;
    }
    return Icons[icon] ?? FunctionHelpers.nullFn;
};
