'use client';

import React, { useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';
import clsx from 'clsx';
import { DefaultValueConfig } from '@headless-workspace/config';
import {
    DynamicTagValue,
    HeaderConnectionBannerItemId,
    HeaderConnectionBannerItemValue,
    HeaderSlotValue,
} from '@headless-workspace/core-domain';
import { UIHoverBanner } from '@headless-workspace/core-ui';
import { getIcon, GlowDivider, PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import {
    AppointmentConnectionBannerItem,
    ConnectionBannerGreeting,
    ConnectionBannerItem,
    ConnectionBannerItemWrapper,
    ConnectionBannerLogoutButton,
    ConnectionDataLayerLabel,
    ConnectionHoverButton,
    getConnectedUserInfo,
    OfferToastBannerConnection,
    useUserInfo,
} from '../../../../../src';

type ConnectionBannerProps = PropsWithLocale & {
    hoverButton: HeaderSlotValue;
    bannerItems: HeaderConnectionBannerItemValue[];
    isEnableOffers?: boolean;
    dynamicOfferRules?: DynamicTagValue;
    hostUrl: string;
};

const getConnectionBannerItemFromId = (bannerItem: HeaderConnectionBannerItemValue) => {
    switch (bannerItem.id) {
        case HeaderConnectionBannerItemId.FollowMyOrder:
        case HeaderConnectionBannerItemId.MyOffers:
            return (
                <ConnectionBannerItemWrapper
                    key={bannerItem.id}
                    connectionDataLayer={ConnectionDataLayerLabel[bannerItem.id]}
                    {...bannerItem}
                />
            );
        case HeaderConnectionBannerItemId.MyAccount:
        case HeaderConnectionBannerItemId.MyOrders:
        case HeaderConnectionBannerItemId.MyLoyaltyProgram:
        case HeaderConnectionBannerItemId.MySettings:
        case HeaderConnectionBannerItemId.MyMessages:
        case HeaderConnectionBannerItemId.Help:
        case HeaderConnectionBannerItemId.MyNews:
            return (
                <ConnectionBannerItem
                    key={bannerItem.id}
                    Icon={getIcon(bannerItem.iconName)}
                    href={bannerItem.href ?? DefaultValueConfig.href}
                    dataLayerLabel={ConnectionDataLayerLabel[bannerItem.id]}
                    {...bannerItem}
                />
            );
        case HeaderConnectionBannerItemId.MakeAnAppointment:
            return <AppointmentConnectionBannerItem key={bannerItem.id} {...bannerItem} />;

        default:
            return null;
    }
};

export const ConnectionBanner: Component<ConnectionBannerProps> = ({
    locale,
    hoverButton,
    bannerItems,
    dynamicOfferRules,
    isEnableOffers,
    hostUrl,
}) => {
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const pathname = usePathname();
    const { result: userInfoResult, isLoading } = useUserInfo();

    const userInfo = getConnectedUserInfo(userInfoResult);

    const shouldDisplayOffer = isEnableOffers && dynamicOfferRules && userInfo?.offer;

    const onLogout = (state: boolean) => {
        setIsLoggingOut(state);
    };

    useEffect(() => {
        setIsLoggingOut(false);
    }, [pathname]);

    return (
        <UIHoverBanner
            className={clsx('flex-row justify-start gap-2.5', 'w-fullViewport desktop:w-maxWidth', 'fill-text-primary')}
            hoverItem={<ConnectionHoverButton isLoggingOut={isLoggingOut} locale={locale} {...hoverButton} />}
        >
            <ConnectionBannerGreeting
                isLoggingOut={isLoggingOut}
                userInfo={userInfo}
                isLoading={isLoading}
                locale={locale}
            />
            <GlowDivider className={'gap-1'} vertical={true} />
            <div className={'grid grid-cols-2 gap-x-2.5 gap-y-1 auto-rows-max'}>
                {bannerItems.map((bannerItem) => getConnectionBannerItemFromId(bannerItem))}
                <ConnectionBannerLogoutButton hostUrl={hostUrl} isLoggingOut={isLoggingOut} onLogout={onLogout} />
                {shouldDisplayOffer && (
                    <OfferToastBannerConnection dynamicOfferRules={dynamicOfferRules} offer={userInfo.offer} />
                )}
            </div>
        </UIHoverBanner>
    );
};
