import { Locale, MemoryCacheKey } from '@headless-workspace/config';
import { AccessTokenCacheDTO, AccessTokenLastUpdateCacheDTO } from './CacheDTO';
import { createLocalizedCacheKey } from './cacheKey';

const serverCacheMap: Map<string, string | number | undefined> = new Map();

export const serverMemoryCache = {
    getAccessToken(locale: Locale): string | undefined {
        const cacheKey = createLocalizedCacheKey(MemoryCacheKey.AccessToken, locale);
        const cachedAccessToken = serverCacheMap.get(cacheKey);
        const parseResult = AccessTokenCacheDTO.safeParse(cachedAccessToken);
        return parseResult.success ? parseResult.data : undefined;
    },
    getAccessTokenTTL(locale: Locale): number | undefined {
        const cacheKey = createLocalizedCacheKey(MemoryCacheKey.AccessTokenLastUpdate, locale);
        const cachedAccessTokenLastUpdate = serverCacheMap.get(cacheKey);
        const parseResult = AccessTokenLastUpdateCacheDTO.safeParse(cachedAccessTokenLastUpdate);
        return parseResult.success ? parseResult.data : undefined;
    },
    setAccessToken(accessToken: string, locale: Locale): void {
        serverCacheMap.set(createLocalizedCacheKey(MemoryCacheKey.AccessToken, locale), accessToken);
        serverCacheMap.set(createLocalizedCacheKey(MemoryCacheKey.AccessTokenLastUpdate, locale), Date.now());
    },
};
