import { DeliveryMethod } from '@headless-workspace/typings';
import { StoreDTO } from './StoreDTO';
import { StoreSchedule } from './StoreSchedule';
import { StoreService } from './StoreService';

type StoreProductsAvailability = { id: string; isAvailable: boolean };

export type StoreInfo = Readonly<{
    id: string;
    name: string;
    address: string;
    postalCode: string;
    city: string;
    phone: string;
    countryCode: string;
    latitude: number;
    longitude: number;
    schedule: StoreSchedule[];
    distance?: number;
    distanceText?: string;
    exceptionalOpeningDays?: {
        schedule: StoreSchedule[];
        exceptionalOpeningDaysText?: string;
    };
    exceptionalClosingDays?: {
        days: string[];
        exceptionalClosingDaysText?: string;
    };
    availableServices: StoreService[];
    shippingMethods: {
        groupId: DeliveryMethod;
        isFree: boolean;
        deliveryMessage: string;
    };
    products: StoreProductsAvailability[];
}>;

export const mapStoreInfo = (dto: StoreDTO): StoreInfo => {
    return {
        id: dto.id,
        name: dto.name,
        address: dto.address1,
        postalCode: dto.postalCode,
        city: dto.city,
        phone: dto.phone,
        countryCode: dto.countryCode,
        latitude: dto.latitude,
        longitude: dto.longitude,
        distance: dto.distance,
        distanceText: dto.distanceText,
        schedule: dto.schedule.map((schedule) => ({
            day: schedule.day,
            date: schedule.date,
            workingHours: schedule.workingHours,
            workingHoursText: schedule.workingHoursText,
        })),
        exceptionalOpeningDays: dto.exceptionalOpeningDays && {
            schedule: dto.exceptionalOpeningDays.schedule.map((schedule) => ({
                day: schedule.day,
                date: schedule.date,
                workingHours: schedule.workingHours,
                workingHoursText: schedule.workingHoursText,
            })),
            exceptionalOpeningDaysText: dto.exceptionalOpeningDays.exceptionalOpeningDaysText,
        },
        exceptionalClosingDays: dto.exceptionalClosingDays && {
            days: dto.exceptionalClosingDays.days,
            exceptionalClosingDaysText: dto.exceptionalClosingDays.exceptionalClosingDaysText,
        },
        availableServices: dto.services,
        shippingMethods: {
            groupId: DeliveryMethod.clickandcollect, // TODO: demock
            isFree: false, // TODO: demock
            deliveryMessage: '', // TODO: demock
        },
        products: dto.products ?? [],
    };
};
