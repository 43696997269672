'use client';

import { useTranslations } from 'next-intl';
import { buildRoute, localitiesConfig } from '@headless-workspace/config';
import { DropDownOption, GlowDropDownSearch, Icons } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { FormatHelpers } from '@headless-workspace/utils';
import { useDebounceCallback } from 'usehooks-ts';
import { useAppLocale, useNavigation } from '../../../../../src';
import { useStoreAutocomplete } from './useStoreAutocomplete';
import { useUserGeolocation } from './useUserGeolocation';

const STORE_SEARCH_ID = 'store-search';

export const StoreSearchContainer: Component = () => {
    const t = useTranslations('Header.storesAndServices');
    const locale = useAppLocale();
    const { localitiesResults, searchLocalities, isLoading } = useStoreAutocomplete();
    const searchLocalitiesDebounced = useDebounceCallback(searchLocalities, localitiesConfig.debounceTime, {
        leading: true,
    });
    const { userAddress, getCurrentPosition } = useUserGeolocation();
    const navigation = useNavigation();

    const onStoreSearch = (input: string) => {
        searchLocalitiesDebounced(input);
    };

    const onUserLocationSearch = () => {
        if (userAddress) {
            const formattedUserAddress = FormatHelpers.formatAddress(
                userAddress.postalCode,
                userAddress.locality,
                userAddress.country,
            );
            navigateToStore(formattedUserAddress);
        } else {
            getCurrentPosition();
        }
    };

    const navigateToStore = (address: string) => {
        const storeRedirectUrl = buildRoute(locale, 'allStores', {
            address: address,
        });
        navigation.push(storeRedirectUrl);
    };

    const handleLocalityClick = (option: DropDownOption) => {
        searchLocalitiesDebounced(option.value);
        navigateToStore(option.value);
    };

    return (
        <div className={'relative flex flex-col'}>
            <GlowDropDownSearch
                id={STORE_SEARCH_ID}
                defaultValue={
                    userAddress
                        ? FormatHelpers.formatAddress(userAddress.postalCode, userAddress.locality, userAddress.country)
                        : ''
                }
                optionsList={localitiesResults}
                title={t('banner.label.location')}
                onSearchChange={onStoreSearch}
                ariaLabelInput={t('banner.label.placeholder')}
                helperLabel={t('banner.label.placeholder')}
                rightSearchInputIcon={Icons.Location}
                isLoading={isLoading}
                onSearchRightIconSubmit={() => onUserLocationSearch()}
                handleOptionClick={(option) => handleLocalityClick(option)}
            />
        </div>
    );
};
