import { PropsWithChildren, ReactNode } from 'react';
import clsx from 'clsx';
import { Component } from '@headless-workspace/typings';
import { GlowTextBody, GlowTitle } from '../../atoms';
import { PropsWithClassName, PropsWithFontFamily } from '../../props';
import { GlowButton } from '../cta';

type GlowSectionContainerProps = PropsWithClassName &
    Partial<PropsWithFontFamily> & {
        title: string | ReactNode;
        subtitle?: string;
        button?: {
            label: string;
            href: string;
        };
        isShowSubtitleInMobile?: boolean;
        titleContainerClassName?: string;
        titleClassName?: string;
        subTitleClassName?: string;
        dataLayerClassName?: string;
    } & PropsWithChildren;

export const GlowContainerSection: Component<GlowSectionContainerProps> = ({
    title,
    subtitle,
    button,
    className,
    children,
    titleContainerClassName,
    titleClassName,
    subTitleClassName,
    dataLayerClassName,
    isShowSubtitleInMobile = false,
    fontFamily = 'brand',
}) => {
    return (
        <section className={clsx('flex flex-col', className)}>
            <div
                className={clsx(
                    'grid grid-cols-1fr_auto',
                    'gap-x-1 gap-y-0.5 tablet:gap-y-0.25 w-full',
                    'justify-between items-center',
                    titleContainerClassName,
                )}
            >
                <GlowTitle
                    fontSize={'title2'}
                    TagName={'h2'}
                    innerHTML={title}
                    fontFamily={fontFamily}
                    className={clsx('col-start-1', !subtitle && 'row-start-1 row-span-2 items-center', titleClassName)}
                />
                {subtitle && (
                    <GlowTextBody
                        innerHTML={subtitle}
                        fontFamily={fontFamily}
                        className={clsx(
                            'row-start-2 col-span-2',
                            'tablet:col-start-1 tablet:col-span-1',
                            !isShowSubtitleInMobile && 'hidden tablet:block',
                            subTitleClassName,
                        )}
                    />
                )}
                <div className={clsx('col-start-2 grid items-center pl-0', 'tablet:row-span-2 tablet:row-start-1')}>
                    {button && (
                        <GlowButton
                            className={dataLayerClassName}
                            label={button.label}
                            fontSize={'bodySmall'}
                            href={button.href}
                            fontFamily={fontFamily ?? 'brand'}
                            size={'medium'}
                            variant={'tertiary'}
                        />
                    )}
                </div>
            </div>
            {children}
        </section>
    );
};
