'use client';

import { createRef, ReactNode, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Component } from '@headless-workspace/typings';
import { checkOverflow, HtmlHelpers, ScrollPosition } from '@headless-workspace/utils';
import { GlowClickable, GlowIcon, Icons, PropsWithAriaLabel, PropsWithOnClick } from '../../../../src';
import { GlowCTA } from '../cta/GlowCTA';

type NavigationButtonProps = Required<PropsWithAriaLabel> &
    Pick<PropsWithOnClick, 'onClick'> & {
        reverse?: boolean;
    };

const NavigationButton: Component<NavigationButtonProps> = ({ ariaLabel, onClick, reverse }) => (
    <GlowClickable
        className={clsx(
            reverse ? 'left-0 rotate-180' : 'right-0',
            'absolute z-paletteSelectorNavigationButton mx-1 p-0.5 border-medium border-solid border-border-primary rounded-0.5 bg-surface-primary',
            'hidden group-hover:block',
        )}
        ariaLabel={ariaLabel}
        content={{ icon: <GlowIcon Icon={Icons.ChevronRight} /> }}
        onClick={onClick}
    />
);

/** w-paletteItem (40 px) + gap-0.5 (8 px) = 48 px */
const PALETTE_ITEM_WIDTH = 48;

type WithPaletteItem = {
    id: string;
    ariaLabel: string;
};

type GlowPaletteProps<T extends WithPaletteItem> = {
    previousButtonAriaLabel: string;
    nextButtonAriaLabel: string;
    items: T[];
    renderItem: (item: T, index: number) => ReactNode;
    onClick: (id: string) => void;
    itemContainerClassName?: string;
};

/**
 *  A component that renders a product palette variant.
 * @param previousButtonAriaLabel - The previous navigation button aria label.
 * @param nextButtonAriaLabel - The next navigation button aria label.
 * @param items - The items to render.
 * @param renderItem - The render function for each item.
 * @param onClick - The click event handler for each item.
 * @param itemContainerClassName - Additional style for each item. The className will be added to the <button />.
 */
export const GlowPalette = <T extends WithPaletteItem>({
    previousButtonAriaLabel,
    nextButtonAriaLabel,
    items,
    renderItem,
    onClick,
    itemContainerClassName,
}: GlowPaletteProps<T>): ReactNode => {
    const [showPrevButton, setShowPrevButton] = useState(false);
    const [showNextButton, setShowNextButton] = useState(true);

    const ulRef = createRef<HTMLUListElement>();

    const scrollToItem = (type: 'prev' | 'next') => {
        const ulElement = ulRef.current;

        if (ulElement) {
            const scrollAmount =
                type === 'next' ? ulElement.scrollLeft + PALETTE_ITEM_WIDTH : ulElement.scrollLeft - PALETTE_ITEM_WIDTH;

            ulElement.scrollTo({
                left: scrollAmount,
                behavior: 'smooth',
            });
        }
    };

    const prevButtonHandler = () => scrollToItem('prev');
    const nextButtonHandler = () => scrollToItem('next');

    useEffect(() => {
        const ulElement = ulRef.current;
        const checkVisibility = () => {
            if (ulElement) {
                const isOverflowing = checkOverflow(ulElement, 'x');

                if (isOverflowing) {
                    const checkScrollPositionListener = () => {
                        const scrollPosition = HtmlHelpers.getHorizontalScrollPosition(ulElement);
                        setShowPrevButton(scrollPosition !== ScrollPosition.Start);
                        setShowNextButton(scrollPosition !== ScrollPosition.End);
                    };
                    checkScrollPositionListener();
                    ulElement.addEventListener('scroll', checkScrollPositionListener);

                    return () => {
                        ulElement.removeEventListener('scroll', checkScrollPositionListener);
                    };
                } else {
                    setShowPrevButton(false);
                    setShowNextButton(false);
                }
            }
        };
        checkVisibility();
        window.addEventListener('resize', checkVisibility);

        return () => {
            window.removeEventListener('resize', checkVisibility);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={'relative flex flex-row items-center overflow-hidden px-1 py-0.5 group'}>
            {showPrevButton && (
                <NavigationButton onClick={prevButtonHandler} ariaLabel={previousButtonAriaLabel} reverse />
            )}
            <ul
                className={'relative flex gap-0.5 overflow-x-scroll scrollbar-hide ease-in duration-moderate1'}
                ref={ulRef}
            >
                {items.map((item, index) => (
                    <li key={`palette-item-${item.id}`} className={'flex'}>
                        <GlowCTA
                            className={clsx('w-paletteItem aspect-1/1', itemContainerClassName)}
                            onClick={() => onClick(item.id)}
                            ariaLabel={item.ariaLabel}
                        >
                            {renderItem(item, index)}
                        </GlowCTA>
                    </li>
                ))}
            </ul>
            {showNextButton && <NavigationButton onClick={nextButtonHandler} ariaLabel={nextButtonAriaLabel} />}
        </div>
    );
};
