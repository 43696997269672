import { CountryCode } from '@headless-workspace/config';
import { FeatureFlagDTO } from './FeatureFlagDTO';

export type CountrySelectorValue = {
    title: string;
    description: string;
    countries: Readonly<{
        name?: string;
        code: CountryCode;
        tld: string;
    }>[];
};

export type CountrySelector = {
    enabled?: boolean;
    value?: CountrySelectorValue;
};

export type FeatureFlagValue = {
    isOpenBarServicesEnabled: boolean;
    isClickAndCollectDisplayed: boolean;
    isWishlistDisplayed: boolean;
    isNewsletterEnabled: boolean;
};

export const mapFeatureFlag = (dto: FeatureFlagDTO): FeatureFlagValue => {
    return {
        isOpenBarServicesEnabled: dto.isOpenBarServicesEnabled,
        isClickAndCollectDisplayed: dto.isClickAndCollectDisplayed,
        isWishlistDisplayed: dto.isWishlistDisplayed,
        isNewsletterEnabled: dto.isNewsletterEnabled,
    };
};

export const mapCountrySelector = (dto: FeatureFlagDTO): CountrySelector => {
    if (!dto.countrySelector) {
        return {};
    }

    return {
        enabled: dto.countrySelector.enabled,
        value: {
            title: dto.countrySelector.value.title,
            description: dto.countrySelector.value.description,
            countries: dto.countrySelector.value.countries.map((country) => ({
                name: country.name,
                code: CountryCode[country.code],
                tld: country.tld,
            })),
        },
    };
};
