import { BasketProductValue, PromotionType } from '../../../../src';

export const BasketBusinessRules = (
    products: BasketProductValue[],
    productsCount: number,
    totalBeforeDiscount: number,
    totalForFreeShipping: number,
) => {
    const productsWithoutBonus = products.filter((product) => !product.isBonus);
    const productsDisplayed = productsWithoutBonus.reverse().slice(0, 3);

    const quantityOfProductsDisplayed = productsDisplayed.reduce((quantity, product) => quantity + product.quantity, 0);

    const quantityOfProductsNotDisplayed = productsCount - quantityOfProductsDisplayed;

    const checkIsFreeShipping = totalForFreeShipping <= totalBeforeDiscount;
    const hasOmnibusProduct = products.some((product) => product.promotion === PromotionType.OMNIBUS);

    return {
        productsDisplayed,
        quantityOfProductsNotDisplayed,
        checkIsFreeShipping,
        hasOmnibusProduct,
    };
};
