'use client';

import { useTranslations } from 'next-intl';
import { ServerActionName } from '@headless-workspace/config';
import { AsyncData, Result, ResultEmpty, ResultFailure } from '@headless-workspace/core-domain';
import useSWRMutation, { TriggerWithoutArgs } from 'swr/mutation';
import {
    FavoriteStoreErrors,
    FavoriteStoreUpdateMode,
    getFavoriteStoreServerAction,
    StoreInfo,
    updateFavoriteStoreServerAction,
    UserRevalidationKey,
    useSafeSWR,
} from '../../../../../src';
import { CommonDI, UserCookiesRepositoryType } from '../../../../DI';

export type MutationTriggerReturnType = TriggerWithoutArgs<
    Result<StoreInfo>,
    unknown,
    typeof UserRevalidationKey.FavoriteStore
>;

type UseFavoriteStoreInfoReturnType = AsyncData<StoreInfo> & {
    deleteFavoriteStore: MutationTriggerReturnType;
};

/**
 * useFavoriteStoreInfo
 * @param hostUrl The host URL needed when deleting the cookie
 * @returns An object containing the store information and a function to delete the favorite store
 */
export const useFavoriteStoreInfo = (hostUrl?: string): UseFavoriteStoreInfoReturnType => {
    const t = useTranslations('Header.storesAndServices.banner.error');
    const { data, isLoading, error } = useSafeSWR(
        UserRevalidationKey.FavoriteStore,
        ServerActionName.getFavoriteStore,
        getFavoriteStoreServerAction,
    );
    const { trigger: triggerDeleteFavoriteStore } = useSWRMutation<
        Result<StoreInfo>,
        unknown,
        typeof UserRevalidationKey.FavoriteStore
    >(UserRevalidationKey.FavoriteStore, async (url, { arg }) => {
        void CommonDI.get(UserCookiesRepositoryType).deleteFavoriteStoreCookie(hostUrl ?? '');
        return updateFavoriteStoreServerAction(FavoriteStoreUpdateMode.Remove);
    });

    if (!data) {
        if (error) {
            return {
                isLoading,
                result: ResultFailure(
                    FavoriteStoreErrors.Default,
                    error instanceof Error ? error.message : t('defaultError'),
                ),
                deleteFavoriteStore: triggerDeleteFavoriteStore,
            };
        }

        return { isLoading, result: ResultEmpty(), deleteFavoriteStore: triggerDeleteFavoriteStore };
    }

    return { isLoading, result: data, deleteFavoriteStore: triggerDeleteFavoriteStore };
};
