'use client';

import { useEffect, useRef } from 'react';
import { KeyboardEventKey } from '@headless-workspace/config';
import { Component } from '@headless-workspace/typings';

type FocusTrapProps = {
    isActive?: boolean;
    children: React.ReactNode;
};

const FOCUSABLE_ELEMENTS = [
    'a[href]',
    'area[href]',
    'input:not([disabled]):not([type="hidden"]):not([aria-hidden])',
    'select:not([disabled]):not([aria-hidden])',
    'textarea:not([disabled]):not([aria-hidden])',
    'button:not([disabled]):not([aria-hidden])',
    'iframe',
    'object',
    'embed',
    '[contenteditable]',
    '[tabindex]:not([tabindex^="-"])',
];
export const FocusTrap: Component<FocusTrapProps> = ({ isActive = true, children }) => {

    const containerRef = useRef<HTMLDivElement>(null);
    const previousFocusRef = useRef<HTMLElement | null>(null);

    useEffect(() => {
        if (!isActive || !containerRef.current) {
            return;
        }

        // Store the currently focused element before activating the trap
        previousFocusRef.current = document.activeElement as HTMLElement;

        const focusableElements = containerRef.current.querySelectorAll<HTMLElement>(FOCUSABLE_ELEMENTS.join(', '));
        const firstFocusableElement = focusableElements[0];
        const lastFocusableElement = focusableElements[focusableElements.length - 1];

        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key !== KeyboardEventKey.Tab) {
                return;
            }

            // Loop focus back to the first element when pressing tab on the last element
            if (e.shiftKey) {
                if (document.activeElement === firstFocusableElement) {
                    e.preventDefault();
                    lastFocusableElement?.focus();
                }
            } else {
                if (document.activeElement === lastFocusableElement) {
                    e.preventDefault();
                    firstFocusableElement?.focus();
                }
            }
        };

        // Focus first element when trap becomes active
        firstFocusableElement?.focus();

        document.addEventListener('keydown', handleKeyDown);

        // Cleanup function
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            // Restore focus when trap is deactivated
            if (previousFocusRef.current) {
                previousFocusRef.current.focus();
            }
        };
    }, [isActive]);

    return <div ref={containerRef}>{children}</div>;
};
