'use client';

import { useEffect, useRef, useState } from 'react';
import { clsx } from 'clsx';
import { Component } from '@headless-workspace/typings';
import { checkOverflow } from '@headless-workspace/utils';
import { Tabs, TabsList } from '@radix-ui/react-tabs';
import { PropsWithId } from '../../props';
import { IdProvider } from './IdProvider';
import { NavigationButton } from './NavigationButton';
import { Tab, useTabs } from './hooks';

export type GlowTabsProps = Required<PropsWithId> & {
    previousButtonAriaLabel: string;
    nextButtonAriaLabel: string;
    tabs: Tab[];
    defaultIndex?: number;
    contentClassName?: string;
};

/**
 * A component that renders tabs.
 * @param id - The component unique ID prefix.
 * @param previousButtonAriaLabel - The previous tab navigation button aria label.
 * @param nextButtonAriaLabel - The next tab navigation button aria label.
 * @param tabs - The tabs configuration.
 * @param classNameContent - The class name of content
 * @param defaultIndex - The default index
 */
export const GlowTabs: Component<GlowTabsProps> = ({
    id,
    previousButtonAriaLabel,
    nextButtonAriaLabel,
    tabs,
    contentClassName,
    defaultIndex,
}) => {
    const { labels, content, current, isFirst, isLast, show, showPrevious, showNext } = useTabs(id, tabs, defaultIndex);
    const tabListRef = useRef<HTMLDivElement>(null);
    const [hasOverflow, setHasOverflow] = useState(false);

    useEffect(() => {
        const updateOverflowStatus = () => {
            setHasOverflow(checkOverflow(tabListRef.current, 'x'));
        };

        updateOverflowStatus();
        window.addEventListener('resize', updateOverflowStatus);

        return () => {
            window.removeEventListener('resize', updateOverflowStatus);
        };
    }, [tabs]);

    return (
        <Tabs value={current} onValueChange={show}>
            <TabsList
                className={'group relative flex h-tablist border-b-medium border-solid border-b-border-primary'}
                loop={false}
            >
                {hasOverflow && !isFirst && (
                    <NavigationButton ariaLabel={previousButtonAriaLabel} onClick={showPrevious} reverse />
                )}
                <div data-testid="test-tabList" ref={tabListRef} className={'flex-1 flex overflow-auto scrollbar-hide'}>
                    <IdProvider value={current}>{labels}</IdProvider>
                </div>
                {hasOverflow && !isLast && <NavigationButton ariaLabel={nextButtonAriaLabel} onClick={showNext} />}
            </TabsList>
            <div className={clsx('p-1', contentClassName)}>{content}</div>
        </Tabs>
    );
};
