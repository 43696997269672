/**
 * European countries
 * Warning: we have the same const in index.d.ts
 */
export const EUROPEAN_COUNTRY = {
    FR: 'FR',
    DE: 'DE',
    CH: 'CH',
    TR: 'TR',
    DK: 'DK',
    SE: 'SE',
    GR: 'GR',
    RO: 'RO',
    PL: 'PL',
    CZ: 'CZ',
    PT: 'PT',
    ES: 'ES',
    IT: 'IT',
} as const;

export type EuropeanCountryCode = keyof typeof EUROPEAN_COUNTRY;
