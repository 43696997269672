'use client';

import React from 'react';
import { PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { ConnectedUserInfo } from '../../../../../src';
import { ConnectionBannerConnectedGreeting } from './ConnectionBannerConnectedGreeting';
import { ConnectionBannerGreetingSkeleton } from './ConnectionBannerGreetingSkeleton';
import { ConnectionBannerNotConnectedGreeting } from './ConnectionBannerNotConnectedGreeting';

type ConnectionBannerGreetingProps = PropsWithLocale & {
    isLoggingOut?: boolean;
    userInfo: ConnectedUserInfo | undefined;
    isLoading: boolean;
};

export const ConnectionBannerGreeting: Component<ConnectionBannerGreetingProps> = ({
    locale,
    userInfo,
    isLoading,
    isLoggingOut = false,
}) => {
    return (
        <div className={'flex flex-col items-start gap-1 w-connectionBannerInfo'}>
            {isLoading ? (
                <ConnectionBannerGreetingSkeleton />
            ) : userInfo ? (
                <ConnectionBannerConnectedGreeting locale={locale} userInfo={userInfo} isLoggingOut={isLoggingOut} />
            ) : (
                <ConnectionBannerNotConnectedGreeting locale={locale} />
            )}
        </div>
    );
};
