'use client';

import { ChangeEvent, ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import {
    GlowButton,
    GlowClickable,
    GlowIcon,
    GlowInput,
    GlowTitle,
    Icons,
    PropsWithClassName,
} from '@headless-workspace/glow-ds';
import { DateHelpers, NumberHelpers } from '@headless-workspace/utils';
import { deleteEffectiveDateServerAction, setEffectiveDateServerAction, useClientData } from '../../../../src';

type ShopInTheFutureProps = PropsWithClassName & {
    onPreviewClose: () => void;
    onPreviewHide: () => void;
};

export const ShopInTheFuture = ({ className, onPreviewClose, onPreviewHide }: ShopInTheFutureProps): ReactNode => {
    const t = useTranslations('Preview.shopInTheFuture');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const { updateTimeMachineDate } = useClientData();

    const onChangeInputValue = (inputType: 'date' | 'time') => (event: ChangeEvent<HTMLInputElement>) => {
        switch (inputType) {
            case 'date':
                setDate(event.target.value);
                break;
            case 'time':
                setTime(event.target.value);
                break;
        }
    };

    const onValidate = () => {
        const isoDate = DateHelpers.toISODate(date, time);
        updateTimeMachineDate(isoDate);
        void setEffectiveDateServerAction(isoDate);
        onPreviewClose();
    };

    const initDateTime = useCallback((reset: boolean) => {
        let dateNow = new Date();
        if (window && !reset) {
            const currentTime = NumberHelpers.parseNumber(
                new URLSearchParams(window.location.search).get('time') ?? '',
            );
            if (currentTime && DateHelpers.isValidDate(currentTime)) {
                dateNow = new Date(currentTime);
            }
        }
        setDate(dateNow.toISOString().slice(0, 10));
        setTime(dateNow.toTimeString().slice(0, 5));
    }, []);

    const onClose = useCallback(() => {
        onPreviewClose();
    }, [onPreviewClose]);

    const onReset = useCallback(() => {
        void deleteEffectiveDateServerAction();
        updateTimeMachineDate('');
        initDateTime(true);
        onPreviewClose();
    }, [initDateTime, onPreviewClose, updateTimeMachineDate]);

    useEffect(() => {
        initDateTime(false);
    }, [initDateTime]);

    return (
        <div
            className={clsx(
                'flex flex-col gap-2',
                'bg-background-l1 p-1.5',
                'border-medium border-solid rounded-0.5 border-border-primary',
                className,
            )}
        >
            <div className={'flex flex-row gap-x-2 w-full'}>
                <GlowTitle fontSize={'title2'} text={t('label.title')} />
                <GlowClickable
                    className={'fill-text-primary-fixed hover:fill-text-secondary active:fill-text-secondary-inverse'}
                    content={{ icon: <GlowIcon Icon={Icons.Cross} type={'large'} /> }}
                    onClick={onClose}
                />
            </div>
            <GlowTitle fontSize={'title3'} text={t('label.dateAndTime')} />

            <div className={'flex gap-0.5'}>
                <GlowInput
                    className={'p-0.5 rounded-0.5 border-medium border-background-inverse'}
                    type={'date'}
                    value={date}
                    onChange={onChangeInputValue('date')}
                />
                <GlowInput
                    className={'p-0.5 rounded-0.5 border-medium border-background-inverse'}
                    type={'time'}
                    value={time}
                    onChange={onChangeInputValue('time')}
                />
            </div>

            <div className={'flex items-center gap-1'}>
                <GlowButton label={t('action.ok')} onClick={onValidate} />
                <GlowButton label={t('action.reset')} variant={'tertiary'} onClick={onReset} />
            </div>
            <GlowButton label={t('action.hide')} variant={'secondary'} onClick={onPreviewHide} />
        </div>
    );
};
