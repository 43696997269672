import { z } from 'zod';
import { ScheduleDTO } from './ScheduleDTO';

export const StoreDTO = z.object({
    id: z.string(),
    name: z.string(),
    address1: z.string(),
    postalCode: z.string(),
    city: z.string(),
    phone: z.string(),
    countryCode: z.string(),
    latitude: z.number(),
    longitude: z.number(),
    schedule: z.array(ScheduleDTO),
    distance: z.number().optional(),
    distanceText: z.string().optional(),
    exceptionalOpeningDays: z
        .object({
            schedule: z.array(ScheduleDTO).default([]),
            exceptionalOpeningDaysText: z.string().optional(),
        })
        .optional(),
    exceptionalClosingDays: z
        .object({
            days: z.array(z.string()).default([]),
            exceptionalClosingDaysText: z.string().optional(),
        })
        .optional(),
    services: z
        .array(
            z.object({
                id: z.string(),
                name: z.string(),
            }),
        )
        .default([]),
    deliveryOptions: z.object({
        clickAndCollect: z.boolean(),
        deliveryToStore: z.boolean(),
    }),
    products: z
        .array(
            z.object({
                id: z.string(),
                isAvailable: z.boolean(),
            }),
        )
        .optional(),
});
export const StoreDTOList = z.object({ stores: z.array(StoreDTO) });

export type StoreDTO = z.infer<typeof StoreDTO>;
