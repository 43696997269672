'use client';

import { ChangeEvent, FormEvent, useEffect, KeyboardEvent, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { KeyboardEventKey } from '@headless-workspace/config';
import { Component } from '@headless-workspace/typings';
import { ArrayHelpers, StringHelpers } from '@headless-workspace/utils';
import {
    GlowIcon,
    GlowInput,
    GlowTextBodySmall,
    GlowTextCaption,
    IconComponentType,
    Icons,
    useClickOutside,
} from '../../../../src';
import { GlowClickable } from '../cta';
import { DropdownOptionList } from './DropdownOptionList';
import { CommonDropdownProps, DropDownOption } from './GlowDropDown';

type GlowDropDownSearchProps = CommonDropdownProps & {
    title: string;
    onSearchChange: (input: string) => void;
    placeHolder?: string;
    defaultValue?: string;
    ariaLabelInput: string;
    helperLabel?: string;
    errorMessageLabel?: string;
    isLoading: boolean;
    rightSearchInputIcon?: IconComponentType;
    leftSearchInputIcon?: IconComponentType;
    onSearchRightIconSubmit?: (callback: (input: string) => void) => void;
    handleOptionClick: (option: DropDownOption) => void;
};

export const GlowDropDownSearch: Component<GlowDropDownSearchProps> = ({
    id,
    title,
    errorMessageLabel,
    rightSearchInputIcon,
    leftSearchInputIcon,
    helperLabel,
    optionsList,
    onSearchChange,
    placeHolder,
    ariaLabelInput,
    isLoading,
    onSearchRightIconSubmit,
    handleOptionClick,
    defaultValue,
}) => {
    const [searchInput, setSearchInput] = useState('');
    const [errorMessage, setErrorMessage] = useState(errorMessageLabel);
    const [isOpen, setIsOpen] = useState(false);
    const [focusedIndex, setFocusedIndex] = useState(-1);

    const listRef = useRef<HTMLUListElement>(null);
    const parentRef = useRef<HTMLDivElement>(null);

    useClickOutside(parentRef, () => setIsOpen(false));

    useEffect(() => {
        setSearchInput(defaultValue ?? '');
    }, [defaultValue]);

    const toggleMenu = () => {
        setIsOpen((prevIsOpen) => {
            return !prevIsOpen;
        });
    };

    const filteredData = useMemo(() => {
        const lowerSearch = searchInput.toLowerCase();
        return optionsList.map((option) => {
            const lowerText = option.value.toLowerCase();
            if (lowerText.includes(lowerSearch)) {
                const escapedSearch = lowerSearch.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                const regex = new RegExp(`(${escapedSearch})`, 'gi');
                return {
                    ...option,
                    value: option.value.replace(regex, `<em>$1</em>`),
                };
            }

            return option;
        });
    }, [searchInput, optionsList]);

    const handleOptionListClick = (option: DropDownOption) => {
        const stripTags = StringHelpers.stripTags(option.value);

        setSearchInput(stripTags);
        setErrorMessage(undefined);
        setIsOpen(false);
        handleOptionClick({ id: option.id, value: stripTags });
    };

    const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const inputValue = event.target.value;

        setErrorMessage(undefined);
        setSearchInput(inputValue);
        onSearchChange(inputValue);
    };

    const onSearchSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSearchChange(searchInput);
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        const upFocusIndex = (index: number) => (index + 1) % filteredData.length;
        const downFocusIndex = (index: number) => (index - 1 < 0 ? filteredData.length - 1 : index - 1);

        switch (event.key) {
            case KeyboardEventKey.ArrowDown:
                event.preventDefault();
                if (!isOpen) {
                    setIsOpen(true);
                    setFocusedIndex(0);
                } else {
                    setFocusedIndex((prevIndex) => upFocusIndex(prevIndex));
                }
                break;
            case KeyboardEventKey.ArrowUp:
                event.preventDefault();
                if (isOpen) {
                    setFocusedIndex((prevIndex) => downFocusIndex(prevIndex));
                }
                break;
            case KeyboardEventKey.Enter:
                event.preventDefault();
                if (isOpen && focusedIndex >= 0) {
                    const selectedOption = filteredData[focusedIndex];
                    if (selectedOption) {
                        handleOptionListClick(selectedOption);
                    }
                } else {
                    setIsOpen(true);
                }
                break;
            case KeyboardEventKey.Escape:
                event.preventDefault();
                setIsOpen(false);
                break;
        }
    };

    const onParentSetSearchInput = (input: string) => {
        setSearchInput(input);
    };

    const isFilteredDataNotEmpty = ArrayHelpers.isNotEmpty(filteredData);

    return (
        <div className={'flex flex-col gap-0.5'}>
            {title && <GlowTextBodySmall text={title} />}
            <div
                ref={parentRef}
                className={clsx(
                    'custom-select bg-background-form outline group min-w-dropDown relative z-dropDown',
                    errorMessage
                        ? 'outline-border-error outline-bold'
                        : isOpen
                        ? 'outline-border-brand outline-bold'
                        : 'outline-border-primary outline-medium',
                    isOpen && isFilteredDataNotEmpty ? 'rounded-t-0.5' : 'rounded-0.5',
                )}
                role={'combobox'}
                aria-label={searchInput}
                aria-controls={`options-list-${id}`}
                aria-expanded={isOpen}
                tabIndex={0}
                onKeyDown={handleKeyDown}
            >
                <form
                    className={clsx(
                        'flex flex-row items-center justify-between bg-surface-neutral w-full h-searchBar outline-none relative z-dropDown',
                        isOpen && isFilteredDataNotEmpty ? 'rounded-t-0.5' : 'rounded-0.5',
                    )}
                    onSubmit={onSearchSubmit}
                >
                    <div className={'flex flex-row items-center w-full gap-0.5 px-0.75'}>
                        {leftSearchInputIcon && <GlowIcon Icon={leftSearchInputIcon} type={'large'} />}
                        <GlowInput
                            className={
                                'flex-grow-1 h-auto bg-surface-neutral text-body text-text-primary placeholder:text-text-tertiary overflow-ellipsis outline-none py-0.5'
                            }
                            enterKeyHint={'search'}
                            value={searchInput}
                            onChange={onSearch}
                            onFocus={toggleMenu}
                            placeholder={placeHolder}
                            aria-autocomplete={'none'}
                            type={'search'}
                            aria-label={ariaLabelInput}
                        />
                        {rightSearchInputIcon && onSearchRightIconSubmit && (
                            <GlowClickable
                                onClick={() => onSearchRightIconSubmit(onParentSetSearchInput)}
                                content={{
                                    icon: <GlowIcon Icon={rightSearchInputIcon} type={'medium'} />,
                                }}
                            />
                        )}
                    </div>
                </form>
                {isFilteredDataNotEmpty && (
                    <DropdownOptionList
                        id={id}
                        optionsList={filteredData}
                        isOpen={isOpen}
                        onValueUpdate={handleOptionListClick}
                        focusedIndex={focusedIndex}
                        listRef={listRef}
                        selectedLabel={searchInput}
                        errorMessage={errorMessage}
                        isLoading={isLoading}
                    />
                )}
            </div>
            {helperLabel && <GlowTextCaption text={helperLabel} />}
            <div className={clsx('flex flex-row items-center gap-0.25', !errorMessage && 'invisible')}>
                <GlowIcon Icon={Icons.Warning} type={'small'} fillCSSVariable={'text-error'} />
                <GlowTextCaption text={errorMessage} color={'error'} />
            </div>
        </div>
    );
};
