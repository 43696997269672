'use client';

import { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { TOAST_DEFAULT_DURATION } from '@headless-workspace/config';
import { Component } from '@headless-workspace/typings';
import { flushSync } from 'react-dom';
import { Toast, ToastActionsContext, ToastStateContext, ToastWithTimer } from './ToastContext';

type ToastProviderProps = PropsWithChildren;

const defaultToastOptions: Toast['options'] = {
    animation: true,
    persistent: false,
    duration: TOAST_DEFAULT_DURATION,
    canClose: true,
};
export const ToastProvider: Component<ToastProviderProps> = ({ children }) => {
    const [toast, setToast] = useState<ToastWithTimer>();

    const closeToast = useCallback(() => {
        setToast((currentToast) => {
            if (currentToast?.timer) {
                clearTimeout(currentToast.timer);
            }
            return undefined;
        });
    }, []);

    const showToast = useCallback(
        (incomingToast: Toast) => {
            flushSync(() => {
                if (closeToast) {
                    closeToast();
                }
            });

            const toastWithDefault = {
                ...incomingToast,
                options: { ...defaultToastOptions, ...incomingToast.options },
                isLeaving: false,
            };

            const timer = toastWithDefault.options.persistent
                ? undefined
                : setTimeout(() => {
                      setToast((previousToast) => {
                          if (previousToast) {
                              return { ...previousToast, isLeaving: true };
                          }
                      });
                      setTimeout(() => {
                          closeToast();
                      }, 400);
                  }, toastWithDefault.options.duration);

            const newToast = { ...toastWithDefault, timer };
            setToast(newToast);
        },
        [closeToast],
    );

    const actions = useMemo(
        () => ({
            showToast,
            closeToast,
        }),
        [showToast, closeToast],
    );

    return (
        <ToastActionsContext.Provider value={actions}>
            <ToastStateContext.Provider value={{ toast }}>{children}</ToastStateContext.Provider>
        </ToastActionsContext.Provider>
    );
};
