export const WOOSMAP_BASE_URL = 'https://sdk.woosmap.com/map/map.js?key=';
export const WOOSMAP_ID = 'woosmap';
export const WOOSMAP_DEFAULT_ZOOM = 11;
export const PICK_SELECTED_URL = '/images/Pick_selected.svg';
export const PICK_NOT_SELECTED_URL = '/images/Pick.svg';

export const WOOSMAP_DEFAULT_HEIGHT = 54;
export const WOOSMAP_DEFAULT_WIDTH = 47.45;
const WOOSMAP_DEFAULT_BREAKPOINT = 14;

export const WOOSMAP_DEFAULT_STYLE: woosmap.map.Style = {
    breakPoint: WOOSMAP_DEFAULT_BREAKPOINT,
    rules: [],
    default: {
        color: '#008a2f',
        size: 8,
        minSize: 1,
        icon: {
            url: PICK_NOT_SELECTED_URL,
            scaledSize: {
                height: WOOSMAP_DEFAULT_HEIGHT,
                width: WOOSMAP_DEFAULT_WIDTH,
            },
        },
        selectedIcon: {
            url: PICK_SELECTED_URL,
            scaledSize: {
                height: WOOSMAP_DEFAULT_HEIGHT,
                width: WOOSMAP_DEFAULT_WIDTH,
            },
        },
    },
};
