'use client';

import { useMemo } from 'react';
import clsx from 'clsx';
import { GlowNotificationToast } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { useToastActionsContext, useToastStateContext } from '../../providers';

export const UIToastContainer: Component = () => {
    const { closeToast } = useToastActionsContext();
    const { toast } = useToastStateContext();

    const animationClass = () => {
        if (toast?.options?.animation) {
            if (toast?.isLeaving) {
                return 'animate-toast-slide-out-mobile tablet:animate-toast-slide-out-desktop';
            }
            return 'animate-toast-slide-in-mobile tablet:animate-toast-slide-in-desktop';
        }
    };

    return (
        <div
            className={clsx(
                'fixed flex gap-1 flex-col z-toast bottom-1 w-fullViewport left-0 px-1',
                'tablet:top-headerFixedTablet tablet:right-1 tablet:justify-center tablet:mt-1 tablet:bottom-auto tablet:left-auto tablet:w-auto',
                'desktop:top-headerDesktop',
                'z-12',
            )}
        >
            {toast && (
                <GlowNotificationToast
                    label={{ text: toast.label }}
                    toastType={toast.type}
                    onClose={toast.options?.canClose ? closeToast : undefined}
                    className={clsx(animationClass(), 'tablet:max-w-[20rem] desktop:max-w-[27.5rem]')}
                />
            )}
        </div>
    );
};
