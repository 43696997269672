'use client';

import React from 'react';
import clsx from 'clsx';
import { DynamicHrefValue, HeaderSlotValue } from '@headless-workspace/core-domain';
import { UIHoverBannerButton } from '@headless-workspace/core-ui';
import { getIcon } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { useFindSpecificityRule } from '../../../../../src';

export type MyBeautyProgramBannerProps = HeaderSlotValue;
type DynamicBeautyProgramBannerProps = MyBeautyProgramBannerProps & { dynamicHref: DynamicHrefValue[] };

export const MyBeautyProgramBanner: Component<MyBeautyProgramBannerProps> = ({ dynamicHref, ...props }) => {
    return dynamicHref ? (
        <DynamicBeautyProgramBanner dynamicHref={dynamicHref} {...props} />
    ) : (
        <StaticBeautyProgramBanner {...props} />
    );
};

const DynamicBeautyProgramBanner: Component<DynamicBeautyProgramBannerProps> = ({ dynamicHref, ...props }) => {
    const { isLoading, result } = useFindSpecificityRule(props.type, dynamicHref);
    if (isLoading || result.type !== 'success') {
        return <StaticBeautyProgramBanner {...props} />;
    }

    return (
        <UIHoverBannerButton
            Icon={getIcon(props.iconName)}
            label={props.label}
            href={result.data.href}
            className={clsx('h-full', 'hover:text-underline', props.displayClassName)}
            isLoading={false}
        />
    );
};

const StaticBeautyProgramBanner: Component<MyBeautyProgramBannerProps> = (props) => {
    return (
        <UIHoverBannerButton
            Icon={getIcon(props.iconName)}
            label={props.label}
            href={props.href}
            className={clsx('h-full', 'hover:text-underline', props.displayClassName)}
            isLoading={false}
        />
    );
};
