'use client';

import React from 'react';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { buildRoute } from '@headless-workspace/config';
import { useIsSearchOpen } from '@headless-workspace/core-ui';
import { GlowMenuButton, Icons, PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import {
    DataLayerClassName,
    getConnectedUserInfo,
    SearchContainer,
    useUserInfo,
    VipBrandsValue,
} from '../../../../../src';

type ScrollableHeaderProps = PropsWithLocale & {
    vipBrands: VipBrandsValue;
};

const MOBILE_SEARCH_BAR_ID = 'mobile-search-bar';

export const ScrollableHeader: Component<ScrollableHeaderProps> = ({ locale, vipBrands }) => {
    const t = useTranslations('Header.connection');
    const { result: userInfoResult } = useUserInfo();
    const userInfo = getConnectedUserInfo(userInfoResult);
    const isSearchOpen = useIsSearchOpen();

    const label = userInfo ? t('action.hello', { name: userInfo.firstName }) : t('action.loginOrRegister');
    const href = userInfo ? buildRoute(locale, 'account') : buildRoute(locale, 'connection');

    return (
        <>
            <div
                className={clsx(
                    'block tablet:hidden',
                    'w-full bg-background-l0',
                    'duration-moderate3 h-headerFixedTablet py-0.5 px-1 relative',
                    isSearchOpen && 'z-searchBanner',
                )}
                id={MOBILE_SEARCH_BAR_ID}
            >
                <SearchContainer locale={locale} isMobile vipBrands={vipBrands} />
            </div>
            <GlowMenuButton
                Icon={Icons.Profile}
                label={label}
                ariaLabel={label}
                href={href}
                className={clsx(
                    'desktop:hidden h-headerScrollableTablet py-1',
                    'border-solid border-b-medium border-border-primary',
                    'text-text-primary',
                    `${DataLayerClassName.connection}`,
                )}
                showArrow
            />
        </>
    );
};
