import { DefaultValueConfig } from '@headless-workspace/config';
import { ObjectHelpers } from '@headless-workspace/utils';
import {
    BaseProductValue,
    CustomerBasketDTO,
    mapProductBrandData,
    mapProductPriceData,
    mapURLToImage,
    PromotionType,
    VipBrandsValue,
} from '../../../../src';

export type BasketProductValue = Readonly<
    BaseProductValue & {
        shadeId?: number;
        quantity: number;
        maxQuantity?: number;
        isPreOrderable?: boolean;
        releaseDate?: Date;
        isBonus: boolean;
        productId: string;
        pricePerUnit?: string;
    }
>;

export const mapBasketProduct = (
    dto: CustomerBasketDTO['products'][number],
    currency: string,
    vipBrands: VipBrandsValue,
): BasketProductValue => {
    return {
        id: dto.id,
        productId: dto.productId,
        name: dto.name,
        href: dto.url ?? DefaultValueConfig.href,
        description: dto.description ?? '',
        image: mapURLToImage(dto.imageUrl) ?? ObjectHelpers.createImageWithSrc(DefaultValueConfig.imagePlaceholder),

        shadeId: dto.shadeId,
        quantity: dto.quantity,
        maxQuantity: dto.maxQuantity ?? 0, // FIXME: should be optional ??
        priceBeforeDiscount: dto.formerPrice ?? 0,
        discountPercentage: dto.discountPercentage ?? undefined,
        isPreOrderable: dto.isPreOrderable,
        releaseDate: dto.releaseDate ? new Date(dto.releaseDate) : undefined,
        isBonus: dto.isBonus,
        promotion: dto.promotion as PromotionType,
        ...mapProductBrandData(dto, vipBrands),
        ...mapProductPriceData(dto, currency),
        pricePerUnit: dto.pricePerUnit,
    };
};
