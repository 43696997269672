import React from 'react';
import { useTranslations } from 'next-intl';
import { buildRoute, isUnlimitedLoyaltyLocale } from '@headless-workspace/config';
import { GlowClickable, GlowSkeleton, GlowTextBody, GlowTitle, PropsWithLocale } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { ConnectedUserInfo, isUserGold, RichText } from '../../../../../src';

type ConnectionBannerConnectedGreetingProps = PropsWithLocale & {
    userInfo: ConnectedUserInfo;
    isLoggingOut: boolean;
};

export const ConnectionBannerConnectedGreeting: Component<ConnectionBannerConnectedGreetingProps> = ({
    locale,
    isLoggingOut,
    userInfo,
}) => {
    const t = useTranslations('Header.connection');
    const isUnlimitedGold = userInfo && isUnlimitedLoyaltyLocale(locale) && isUserGold(userInfo);
    const loyaltyProgramText = isUnlimitedGold
        ? 'Header.connection.banner.label.loyaltyProgramPointsUnlimitedGold'
        : 'Header.connection.banner.label.loyaltyProgramPoints';

    if (isLoggingOut) {
        return (
            <>
                <GlowSkeleton width={'17.875rem'} height={'2rem'} />
                <GlowSkeleton width={'100%'} height={'1.25rem'} containerClassName={'w-full'} line={3} />
                <GlowSkeleton height={'1.25rem'} containerClassName={'w-eighty'} />
            </>
        );
    }
    return (
        <>
            <GlowTitle text={t('banner.label.greetingConnected', { name: userInfo.firstName })} fontSize={'title4'} />
            {userInfo.loyalty ? (
                <>
                    <RichText
                        messageKey={loyaltyProgramText}
                        fontSize={'body'}
                        values={{
                            currentPoints: userInfo.loyalty.currentPoints,
                            pointsToNextDiscount: userInfo.loyalty.pointsToNextDiscount,
                        }}
                    />

                    <GlowClickable
                        href={buildRoute(locale, 'loyaltyProgram')}
                        content={{
                            labelElement: (
                                <GlowTextBody
                                    text={t('banner.label.loyaltyProgramEnrolled')}
                                    textDecoration={'underline'}
                                />
                            ),
                        }}
                    />
                </>
            ) : (
                <>
                    <RichText
                        messageKey={'Header.connection.banner.label.loyaltyProgramFeature'}
                        fontSize={'body'}
                        className={'whitespace-pre-line'}
                    />
                    <GlowClickable
                        href={buildRoute(locale, 'loyaltyProgram')}
                        content={{
                            labelElement: (
                                <GlowTextBody
                                    text={t('banner.label.loyaltyProgramNotEnrolled')}
                                    textDecoration={'underline'}
                                />
                            ),
                        }}
                    />
                </>
            )}
        </>
    );
};
