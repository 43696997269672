export enum ServerActionName {
    getBasket = 'getBasket',
    getSearchSuggestions = 'getSearchSuggestions',
    getUserInfo = 'getUserInfo',
    getWishlist = 'getWishlist',
    getFavoriteStore = 'getFavoriteStore',
    getStores = 'getStores',
    updateFavoriteStore = 'updateFavoriteStore',
    updateWishlist = 'updateWishlist',
    subscribeToNewsletter = 'subscribeToNewsletter',
    getIsNotifyMeEnabled = 'getIsNotifyMeEnabled',
    getProductDeliveryInfo = 'getProductDeliveryInfo',
    getHolidays = 'getHolidays',
    getNewsletterSlot = 'getNewsletterSlot',
    updateBasket = 'updateBasket',
    getProductRecommendations = 'getProductRecommendations',
    getRecommendedProducts = 'getRecommendedProducts',
    getPromotionInfoServerAction = 'getPromotionInfoServerAction',
    getReviews = 'getReviews',
    getProductById = 'getProductById',
}
