'use client';

import { useEffect, useState } from 'react';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { buildRoute } from '@headless-workspace/config';
import { UIHoverBanner, UIHoverBannerButton } from '@headless-workspace/core-ui';
import {
    GlowBasketProductPlot,
    GlowButton,
    GlowChip,
    GlowClickable,
    GlowDivider,
    GlowIconSkeleton,
    GlowShippingInformation,
    GlowTextBody,
    GlowTextBodySmall,
    Icons,
    PropsWithLocale,
} from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { StringHelpers } from '@headless-workspace/utils';
import {
    BasketBusinessRules,
    getConnectedUserInfo,
    isUserGold,
    PriceInformation,
    RichText,
    useNavigation,
    useUserInfo,
    VipBrandsValue,
} from '../../../../src';
import { useBasketProductsList } from './useBasketProductsList';

type BasketSummaryContainerProps = PropsWithLocale & {
    totalForFreeShipping: number;
    vipBrands: VipBrandsValue;
    enablePriceExceptionLegalMentions: boolean;
};

const BASKET_INFORMATION_ID = 'basket-information';

export const BasketSummaryContainer: Component<BasketSummaryContainerProps> = ({
    locale,
    totalForFreeShipping,
    vipBrands,
    enablePriceExceptionLegalMentions,
}) => {
    const t = useTranslations('Header.basket.banner');
    const navigation = useNavigation();
    const pathname = usePathname();
    const { isLoading, result: basketResult } = useBasketProductsList(vipBrands);
    const { result: userInfoResult } = useUserInfo();
    const userInfo = getConnectedUserInfo(userInfoResult);
    const isGoldUser = userInfo && isUserGold(userInfo);
    const [isRedirecting, setIsRedirecting] = useState(false);

    const onMoreBasketClick = () => {
        setIsRedirecting(true);
        navigation.push(buildRoute(locale, 'basket'));
    };

    useEffect(() => {
        setIsRedirecting(false);
    }, [pathname]);

    if (basketResult.type !== 'success' || basketResult.data.productsCount <= 0) {
        // FIXME: This if will become useless once we've found a solution to avoid duplicate icons
        return isLoading ? (
            <GlowClickable
                href={buildRoute(locale, 'basket')}
                ariaLabel={t('ariaLabel.emptyBasket')}
                className={clsx('p-0.5 gap-0.25', 'fill-text-primary', 'h-full fill-text-primary')}
                content={{
                    icon: <GlowIconSkeleton Icon={Icons.BasketAnimation} />,
                }}
                disabled={true}
            />
        ) : (
            <UIHoverBannerButton
                className={'h-full fill-text-primary'}
                href={buildRoute(locale, 'basket')}
                ariaLabel={t('ariaLabel.emptyBasket')}
                Icon={Icons.Basket}
                isLoading={false}
            />
        );
    }

    const {
        data: { currency, totalBeforeDiscount, products, productsCount, shipments, total },
    } = basketResult;

    const { productsDisplayed, quantityOfProductsNotDisplayed, checkIsFreeShipping, hasOmnibusProduct } =
        BasketBusinessRules(products, productsCount, totalBeforeDiscount, totalForFreeShipping);

    const quantityOfProductsNotDisplayedLabel =
        quantityOfProductsNotDisplayed > 0
            ? t('label.quantityOfProductsNotDisplayed', {
                  quantityOfProductsNotDisplayed,
              })
            : '';

    const subtotalLabel = t('label.subtotalLabel', {
        subTotalItems: productsCount,
    });

    const totalForShipping = isGoldUser ? totalForFreeShipping : totalBeforeDiscount;
    const isFreeShipping = isGoldUser || checkIsFreeShipping;

    const shippingCostsLabel = shipments?.shippingOptions
        ? t('label.shippingCost', {
              checkIsFreeShipping: isFreeShipping,
              shippingOptions: shipments.shippingOptions,
          })
        : '';

    const shippingCostsWithCurrency = shipments
        ? t('label.shippingCostsWithCurrency', {
              checkIsFreeShipping: isFreeShipping || shipments?.priceOfShipment === 0,
              shipmentPrice: StringHelpers.formatPrice(shipments.priceOfShipment, currency, locale),
          })
        : '';

    const remainderForFreeShippingLabel = t('label.remainderForFreeShippingLabel', {
        checkIsFreeShipping: isFreeShipping,
        amountUntilFreeShipping: StringHelpers.formatPrice(totalForFreeShipping - totalForShipping, currency, locale),
    });

    return (
        <UIHoverBanner
            trailingRight={true}
            className={clsx('flex-row w-basketBanner !p-0')}
            hoverItem={
                <>
                    <UIHoverBannerButton
                        className={'h-full fill-text-primary'}
                        href={buildRoute(locale, 'basket')}
                        ariaLabel={t('ariaLabel.basket')}
                        Icon={Icons.Basket}
                        isLoading={false}
                    />
                    <GlowClickable
                        href={buildRoute(locale, 'basket')}
                        content={{
                            labelElement: (
                                <GlowChip
                                    hasDigit={true}
                                    count={productsCount}
                                    className={
                                        'absolute top-1 left-chipPosition cursor-pointer group-hover:decoration-text-disabled'
                                    }
                                />
                            ),
                        }}
                    />
                </>
            }
        >
            {!isLoading && (
                <div className={'flex flex-col w-full'}>
                    <GlowTextBody
                        fontWeight={'bold'}
                        text={t('label.title', {
                            numberOfItems: basketResult.data?.productsCount ?? 0,
                        })}
                        className={'line-clamp-1  p-1'}
                        TagName={'h4'}
                    />

                    <GlowDivider />
                    <div className={'py-1'}>
                        {productsDisplayed.map((product) => (
                            <GlowBasketProductPlot
                                key={product.id}
                                {...product}
                                price={
                                    product.price > 0
                                        ? StringHelpers.formatPrice(product.price * product.quantity, currency, locale)
                                        : undefined
                                }
                                priceBeforeDiscount={
                                    product.priceBeforeDiscount
                                        ? StringHelpers.formatPrice(product.priceBeforeDiscount, currency, locale)
                                        : undefined
                                }
                                textQuantityLabel={t('label.quantity', {
                                    quantity: product.quantity,
                                })}
                                discountPercentageLabel={t('label.discountPercentage', {
                                    discountPercentage: product.discountPercentage,
                                })}
                                ImageTagName={Image}
                                className={'flex-col'}
                            />
                        ))}
                    </div>

                    <div className={'flex flex-col gap-1 px-1 pb-1'}>
                        <div id={BASKET_INFORMATION_ID}>
                            {quantityOfProductsNotDisplayed > 0 && (
                                <GlowTextBodySmall text={quantityOfProductsNotDisplayedLabel} className={'pb-1'} />
                            )}
                            <PriceInformation
                                className={'pb-0.5'}
                                label={subtotalLabel}
                                price={StringHelpers.formatPrice(totalBeforeDiscount, currency, locale)}
                                fontWeight={'bold'}
                            />
                            <PriceInformation
                                className={'pb-0.5 bold'}
                                label={shippingCostsLabel}
                                price={shippingCostsWithCurrency}
                            />
                            <PriceInformation
                                className={'pb-0.5 bold'}
                                label={t('label.total')}
                                price={StringHelpers.formatPrice(totalBeforeDiscount, currency, locale)}
                                shouldDisplayVat={true}
                                fontWeight={'bold'}
                            />
                        </div>
                        <GlowShippingInformation
                            totalBasket={totalForShipping}
                            totalForFreeShipping={totalForFreeShipping}
                            remainderForFreeShippingLabel={remainderForFreeShippingLabel}
                            currency={currency}
                        />
                        <GlowButton
                            className={'mt-1 w-full transition-backgroundOutline'}
                            label={t('action.seeBasket')}
                            onClick={onMoreBasketClick}
                            disabled={isRedirecting}
                            isSpinnerActive={isRedirecting}
                        />
                        {hasOmnibusProduct && (
                            <RichText
                                TagName={'span'}
                                fontSize={'caption'}
                                className={'line-clamp-2'}
                                messageKey={
                                    enablePriceExceptionLegalMentions
                                        ? 'Header.basket.banner.legalmentions.originalPriceException'
                                        : 'Header.basket.banner.legalmentions.originalPrice'
                                }
                                color={'tertiary'}
                            />
                        )}
                    </div>
                </div>
            )}
        </UIHoverBanner>
    );
};
