'use client';

import React from 'react';
import { DynamicTagValue } from '@headless-workspace/core-domain';
import { GlowInlineToast, ToastType } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { InnerHTML, useFindSpecificityRule, UserOffer } from '../../../../../src';

const OFFER_RULES_ID = 'OfferRules';

type OfferToastBannerConnectionProps = {
    dynamicOfferRules: DynamicTagValue;
    offer: UserOffer;
};

export const OfferToastBannerConnection: Component<OfferToastBannerConnectionProps> = ({
    dynamicOfferRules,
    offer,
}) => {
    const { result, isLoading } = useFindSpecificityRule(OFFER_RULES_ID, [dynamicOfferRules]);

    if (isLoading || result.type !== 'success') {
        return;
    }

    const offerHTML = <InnerHTML rawHtml={{ content: `${offer.title?.content} : ${offer.code?.content}` }} />;

    return <GlowInlineToast label={{ innerHTML: offerHTML }} toastType={ToastType.Info} />;
};
