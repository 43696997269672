import { DefaultValueConfig } from '@headless-workspace/config';
import { getIcon } from '@headless-workspace/glow-ds';
import { Component } from '@headless-workspace/typings';
import { ConnectionBannerItem } from '../../../../../src';
import { ConnectionBannerItemWrapperProps } from './ConnectionBannerItemWrapper';

export const StaticConnectionBanner: Component<ConnectionBannerItemWrapperProps> = (props) => {
    return (
        <ConnectionBannerItem
            key={props.id}
            Icon={getIcon(props.iconName)}
            href={props.href ?? DefaultValueConfig.href}
            dataLayerLabel={props.connectionDataLayer}
            {...props}
        />
    );
};
